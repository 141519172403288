@import "../../../styles/variables";

.modal-full-screen {
  &.ctui-modal {
    border-radius: 0;
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    margin: 0;
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    min-width: 100vw;
    top: 0;
    width: 100vw;
    z-index: 6002;
  }

  .modal-full-screen-header,
  .modal-full-screen-header-back,
  .modal-full-screen-header-cancel {
    h3 {
      padding-left: 42px;

      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  .modal-full-screen-header-back {
    h3 {
      padding-left: 0;
      text-align: center;
    }
  }

  .modal-full-screen-header-cancel {
    h3 {
      padding-left: 0;
      padding-right: 15px;
      text-align: left;
    }
  }

  .modal-full-screen-header-back,
  .modal-full-screen-header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    @media (min-width: 1440px) {
      padding-left: calc(50% - 700px);
      padding-right: calc(50% - 700px);
    }

    h3 {
      font-size: 1.375rem;
      line-height: 1.8em;
      margin: 10px 0;
      width: calc(100% - 38px);
    }

    .button-modal-close,
    .button-modal-back {
      min-height: 48px;
      min-width: 48px;
      padding: 0;

      & > * {
        pointer-events: none;
      }

      .ctui-icon-close {
        min-height: 16px;
        min-width: 16px;
      }

      .ctui-icon-chevron-left {
        min-height: 16px;
      }
    }
  }

  &.modal-full-screen-supply {
    .ctui-modal-header {
      @media (min-width: 1440px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .ctui-modal-body {
    margin: 0 auto;
    width: 100%;

    @media (max-width: 767px) {
      padding: 15px;
      width: 100%;
    }
  }
}

.modal-full-screen-header-cancel,
.modal-full-screen-header-submit {
  justify-content: space-between;
  padding: 10px 15px;

  &.modal-full-screen-header-back,
  &.modal-full-screen-header {
    h3 {
      margin: 0;
      padding: 0 15px 0 0;
    }

    h3,
    h3 > div {
      display: inline-block;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ctui-button {
    left: auto;
    position: relative;
  }
}

.modal-full-screen-header-cancel {
  h3 {
    max-width: 85%;
  }

  &.modal-full-screen-header-back,
  &.modal-full-screen-header {
    h3 {
      max-width: 85%;
    }
  }
}

.modal-full-screen-header-submit {
  &.modal-full-screen-header-back,
  &.modal-full-screen-header {
    h3 {
      max-width: 70%;

      @media (max-width: 767px) {
        left: 50%;
        padding: 0;
        position: absolute;
        text-align: center;
        transform: translate(-50%, 0);
        width: calc(100% - 200px);
      }
    }
  }
}

.modal-full-screen-header-button-wrap {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .modal-full-screen-header-submit & {
    .ctui-button {
      width: auto;

      @media (max-width: 767px) {
        margin-bottom: 0 !important;
      }
    }
  }
}

.modal-full-screen-header-submit-button {
  margin-left: 15px;
}

.modal-full-screen-header-submit-button-attention {
  min-width: 95px;

  @media (max-width: 767px) {
    min-width: 85px;
  }
}

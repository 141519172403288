.copy-spreadsheet-modal {
  .copy-spreadsheet-modal-body {
    height: 100%;
    max-width: 1180px;
    overflow-y: visible;

    .paste-cell-value-language-content {
      ul {
        height: 225px;
        overflow-y: auto;
      }
    }

    .copy-from-spreadsheet-view {
      width: 100%;
    }
  }
}

.copy-from-spreadsheet-body-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

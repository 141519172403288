.loading-with-expiration-observer {
  display: none;
}

.loading-with-expiration-timer {
  display: flex;
  justify-content: center;
  position: relative;
}

.loading-with-expiration-timer-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@import '@classtag/ui/lib/colors';
@import "variables";

$toast-shadow: rgba(231, 206, 206, 0.2);
$toast-shadow-hover: rgba(0, 0, 0, 0.4);
$toast-success: #07bc0c;
$toast-warning: #f1c40f;
$toast-error: #f14c5a;
$toast-bg: #fff;

.Toastify__toast-container {
  left: 0;
  margin: 0 auto;
  max-width: calc(95vw - 30px);
  opacity: 1;
  position: fixed;
  right: 0;
  top: 28px;
  width: 738px;
  z-index: 99999;

  @media only screen and (max-width: 480px) {
    left: 15px;
    margin: 0;
    max-width: calc(100vw - 30px);
    padding: 0;
    width: 100vw;
  }
}

.Toastify__toast {
  background: $toast-bg;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 $toast-shadow;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 0 15px 0 $toast-shadow-hover;
  }

  &.Toastify__toast--info {
    background-image: url("../images/check.svg");
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 21px 21px;
    border-color: $primary;
    color: $primary;
    padding-left: 25px;
  }

  &.Toastify__toast--success {
    border-color: $toast-success;
    color: $toast-success;
  }

  &.Toastify__toast--warning {
    border-color: $toast-warning;
    color: $toast-warning;
  }

  &.Toastify__toast--error {
    border-color: $toast-error;
    color: $toast-error;
  }

  .Toastify__toast-body {
    flex: 1;
    margin: auto 0;
    overflow-wrap: break-word;
    padding: 10px 40px 10px 15px;
    word-break: break-word;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  background-color: transparent;
  background-image: url("../components/Shared/assets/close_grey.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px 8px;
  border: 0;
  height: 8px;
  opacity: 1;
  overflow: hidden;
  padding: 20px;
  position: absolute;
  right: 0;
  text-indent: -100px;
  top: calc(50% - 20px);
  width: 8px;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  animation: Toastify__trackProgress linear 1;
}

@import "@classtag/ui/lib/colors";
@import "../../../styles/variables";
@import '../../../styles/mixins';

.ct-navbar {
  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: $ss-charcoal;
  border-top: 3px solid $ss-emerald-500;
  box-sizing: border-box;
  display: flex;
  gap: 40px;
  height: 61px;
  left: 0;
  margin: 0;
  padding: 0 15px 2px 15px;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 5002;

  .ct-navbar-container-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .connect-app-logo {
      justify-content: center;
      max-width: 80%;
    }
  }

  &.ct-navbar--is-mobile {
    border-top: 0;
  }

  main {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      justify-content: space-between;
    }
  }

  &.ct-navbar--student {
    justify-content: space-between;

    main {
      flex-grow: 0;
      justify-content: space-between;
    }
  }

  .focused:not(.action-directory) & {
    display: none;
  }

  &.ct-navbar--styled {
    border-color: $ss-charcoal;
  }

  .user-thumbnail {
    align-items: center;
  }

  .user-thumbnail-name {
    color: $navy;
    max-width: calc(100% - 61px);
  }

  .ct-navbar-left {
    .ct-navbar-toggle {
      align-items: center;
      display: flex;
    }
  }
}

@media screen and (max-width: 767px) {
  .ct-navbar {
    border-top: 0;
  }
}

.ct-navbar--styled {
  border-bottom: 0;

  .topbar-icon-menu {
    svg {
      path {
        fill: $white;
      }
    }
  }

  .ct-navbar-classroom {
    color: $white;
  }

  .ct-navbar-classroom__image {
    border-color: $white !important;
  }

  .ct-navbar-divider {
    opacity: 0.3;
  }
}

.topbar-separator {
  background: $ss-gray-850;
  height: calc(38px * 2 / 3);
  margin: 0 20px;
  width: 2px;

  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
}

.topbar-user-dropdown-content-container {
  top: 45px;
}

.topbar-user-dropdown {
  height: 36px;

  svg {
    transform: rotate(0deg);
    transition: transform 150ms ease-in-out;
  }

  .ct-navbar--styled & {
    .topbar-right-dropdown-toggle {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.ctui-is-open {
    svg {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.topbar-right-dropdown-toggle {
  &:focus {
    .topbar-right-dropdown-avatar {
      box-shadow: 0 0 0 4px #acd0f5;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

.topbar-user-sidebar-toggle {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;

  .topbar-right-dropdown-avatar {
    box-sizing: border-box;
    margin-right: 0;
  }

  .sidebar-is-opened & {
    .topbar-right-dropdown-avatar {
      box-shadow: 0 0 0 4px #acd0f5;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }
}

.topbar-user-dropdown-content {
  color: $text-primary;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 320px;
  min-width: 256px;
  padding: 0;
}

.topbar-context-dropdown,
.topbar-page-sidebar {

  .google-imported-status {
    display: inline-block;
    margin-right: 5px;
    position: relative;
  }
}

.topbar-context-dropdown {

  .ctui-collapsible {
    border-bottom: 1px solid $border-primary;

    .ctui-collapsible-tab-name {
      font-weight: 600;
    }
  }

  svg {
    transform: rotate(0deg);
    transition: transform 150ms ease-in-out;
  }

  .ct-navbar--styled & {
    .topbar-context-dropdown-toggle {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &.ctui-is-open {
    svg {
      transform: rotate(180deg);
    }
  }

  @media screen and (max-width: 768px) {
    &:not(.topbar-context-dropdown--in-page) {
      display: none;
    }
  }
}

.topbar-context-dropdown-content {
  @include hideScrollbar;
  color: $text-primary;
  max-height: 80vh;
  overflow-y: scroll;
  top: 45px;
  width: 256px;

  &::-webkit-scrollbar {
    display: none;
  }

  .ctui-dropdown__content {
    .ctui-collapsible {
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.add-topbar-dropdown {
  margin-left: 16px;
}

.topbar-right-dropdown-avatar {
  margin-right: 8px;
}

.topbar-context-dropdown-contexnt-name {
  margin-right: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topbar-context-dropdown-toggle {
  align-items: center;
  background: #333539;
  border: 1px solid #65686a;
  border-radius: 9999px;
  display: flex;
  height: 38px;
  justify-content: space-between;
  padding: 0 16px;
  width: 256px;

  &:focus {
    box-shadow: 0 0 0 4px #333539;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .ct-navbar--styled & {
    background: rgba(51,53,57,.5);
    border-color: rgba(101,104,106,.5);
  }
}

.topbar-context-dropdown-toggle-wrapper {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: calc(100% - 20px);
}

.topbar-page-sidebar {
  align-items: center;
  display: flex;
  position: relative;

  button {
    font-size: .875rem;
    justify-content: flex-start;
    width: 100%;

    p {
      display: block;
      margin: 0;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 40px);
    }
  }

  svg {
    margin-right: 8px;
  }
  @media screen and (min-width: 769px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    .ct-navbar--student & {
      button {
        width: 40px;
      }
    }
  }
}

.topbar-menu-items-list {
  display: flex;
  flex-direction: column;
}

.topbar-app-switcher {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 0.75rem;
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 160px;

  @media screen and (max-width: 768px) {
    width: 28px;
  }

  a {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &:hover {
    border-color: #65686a;
    transition: border 150ms ease-in-out 500ms;
  }
}

.topbar-section--page {

  @media screen and (max-width: 600px) {
    width: calc(100% - 110px);
  }
}

.topbar-section-divider {
  background-color: $ss-gray-850;
  height: 24px;
  margin: auto 10px;
  width: 2px;
}

.topbar-section--user {
  align-items: center;
  color: $white;
  display: flex;
  justify-content: flex-end;

  .topbar-button {
    align-items: center;
    background: $primary;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0;
    padding: 0;
    position: relative;
    width: 40px;

    &:focus {
      box-shadow: 0 0 0 4px #acd0f5;
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    .ct-navbar--styled & {
      background: $ss-charcoal;
      border-color: $ss-charcoal;
    }

    .topbar-dropdown-toggle {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }
}

.ct-navbar-supplies-share {
  position: absolute;
  right: 10px;

  @media screen and (min-width: 768px) {
    align-items: center;
    display: flex;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
  }
}

.ct-navbar-inner {
  box-sizing: border-box;
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ct-navbar-left {
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;

  a {
    display: inline-flex;
    min-height: 20px;
  }
}

.ct-navbar-left-summercamp,
.ct-navbar-left-user-account {
  width: 100%;
}

.ct-navbar-divider {
  border-right: 1px solid $border-primary;
  height: 64px;
  margin: 0 16px;
  position: relative;
  width: 1px;
}

.ct-navbar-toggle {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  height: 64px;
  max-width: 500px;
  width: 100%;

  & > img,
  & > svg {
    display: inline-block;
    margin: 16px;
    vertical-align: middle;
  }

  @media (min-width: 1200px) {
    background: transparent;
  }
}

.ct-navbar-classroom {
  align-items: center;
  color: $primary;
  display: inline-flex;
  height: 64px;
  position: relative;
  width: calc(100% - 70px);

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  .google-imported-status {
    left: 30px;
    top: 15px;
  }
}

.ct-navbar-classroom-student {
  display: inline;
  vertical-align: middle;

  h3 {
    color: #061b4e;
    display: inline-block;
    font-weight: 600;
    margin: 0;
  }

  @media (max-width: 600px) {
    min-width: calc(100% - 64px);
    padding-right: 15px;
    text-align: left;
    width: calc(100% - 64px);
  }
}

.ct-navbar-classroom__image {
  border: 1px solid $border-primary;
  border-radius: 50%;
  margin-right: 7px;

  img {
    border-radius: 50%;
    height: 36px;
  }

  .loader-mini-container {
    height: 12px;

    .loader-mini-bar {
      height: 10px;
      margin: 0 3px;
      width: 6px;
    }
  }
}

.ct-navbar-classroom__title {
  background: $white;
  color: $navy-darken;
  max-width: calc(100% - 56px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;

  .ct-navbar--styled & {
    color: $white;
  }
}

@media screen and (max-width: 767px) {
  .ct-navbar-classroom__image {
    display: none;
  }

  .ct-navbar-divider {
    margin: 0 7px;
  }

  .ct-navbar-classroom__title {
    max-width: 100%;
  }
}

.navbar-classroom-avatar {
  box-sizing: content-box;
  margin-right: 8px;
}

.school-page {
  .ct-navbar {
    background-color: $topbar-school-bg-color;

    &.ct-navbar--styled {
      background-color: var(--primary-color);
    }
  }
}

.public-announcement--school {
  .ct-navbar {
    background-color: $topbar-school-bg-color;
  }
}

.school-page,
.public-announcement--school {
  .ct-navbar {
    .ct-navbar-classroom__image {
      background-color: $white;
      border: 1px solid $border-primary;
      border-radius: 50%;
      height: 38px;
      width: 38px;
    }
  }

  .ct-navbar-toggle {
    .ct-navbar-classroom__title {
      background-color: transparent;
    }
  }
}

.public-announcement--district {
  .ct-navbar {
    background-color: $topbar-district-bg-color;

    .ct-navbar-divider {
      border-right-color: $topbar-district-bg-color;
    }
  }
}

.district-page {
  .ct-navbar {
    background-color: $topbar-district-bg-color;

    &.ct-navbar--styled {
      background-color: var(--primary-color);

      .ct-navbar-divider {
        border-right-color: var(--primary-color);
      }
    }
  }
}

.district-page,
.public-announcement--district {
  .ct-navbar-classroom__image {
    background-color: $white;
    border: 1px solid $border-primary;
    border-radius: 50%;
    height: 38px;
    width: 38px;
  }

  .ct-navbar-toggle-fake,
  .ct-navbar-toggle {
    background-color: var(--primary-color);

    .ct-navbar-classroom__title {
      background-color: transparent;
      color: $white;
    }
  }
}

.topbar-icon-menu {
  display: inline-block;
  height: 32px;
  margin: 16px;
  width: 32px;
}

#topbar_dropdown_item_newsletter,
#topbar_dropdown_item_newsletter-smore,
#topbar_dropdown_item_notify {
  @media (max-width: 1023px) {
    display: none;
  }
}

.commerce-goods-logo {
  background: no-repeat center url("../../../images/classtag_goods_logo_green.svg");
  background-size: contain;
  display: inline-block;
  height: 40px;
  vertical-align: middle;
  width: 50%;
}

.topbar-context-dropdown--in-page {
  .topbar-context-dropdown-toggle {
    background: $ss-gray-100;
    border-color: $ss-gray-500;

    &:focus {
      box-shadow: 0 0 0 4px $ss-gray-300;
    }

    @media screen and (max-width: 500px) {
      width: 200px;
    }
  }

  .topbar-context-dropdown-content {
    max-height: 50vh;
  }
}

.topbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 600px) {
  .commerce-goods-logo {
    background: no-repeat left url("../../../images/classtag_goods_logo_mobile_green.svg");
  }
}

@media (max-width: 380px) {
  .commerce-goods-logo {
    background: no-repeat left url("../../../images/classtag_goods_logo_icon_green.svg");
  }
}

@media screen and (max-width: 319px) {
  .ct-navbar-classroom__image {
    &.avatar-wrapper {
      & + .ct-navbar-classroom__title {
        display: none;
      }
    }
  }
}

@keyframes inbox-unread-shake {
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }

  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }

  3%,
  5%,
  7% {
    transform: translate3d(-2px, 0, 0);
  }

  4%,
  6% {
    transform: translate3d(2px, 0, 0);
  }
}

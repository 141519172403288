@import "@classtag/ui/lib/colors";

.new-leader-form {
  .ctui-control-label-container {
    line-height: 1.5625rem;
  }

  .leader-context-information {
    color: $dark-gray;
    font-size: .875rem;
    line-height: 1.1875rem;
  }

  .new-leader-form-field-button {
    height: 2.375rem;
    margin-top: 45px;
    padding: 0 15px;
    vertical-align: top;
  }

  .new-leader-form-field:last-child {
    margin-bottom: 6px;
  }

  .new-leader-form-field-input {
    display: inline-block;
    margin-top: 15px;
    width: calc(100% - 46px);

    input {
      width: 100%;
    }

    // ToDo: cleanup after _bootstrap_custom.scss refactoring
    label {
      margin-bottom: 0;
    }
  }
}

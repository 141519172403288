.layout--integrations {
  .ct-yield {
    height: 100vh;
    padding-bottom: 0;
    padding-top: 61px;

    & > div {
      height: 100%;
      width: 100%;
    }
  }
}
.container--integration {
  height: 100%;
  overflow: hidden;
  width: 100%;

  iframe {
    appearance: none;
    border: 0;
    height: 100%;
    width: 100%;
  }
}
@import "@classtag/ui/lib/colors";
@import "../../../../styles/mixins";

.assign-entities-modal {
  height: 94vh;

  .assign-entities-modal-body {
    overflow-y: hidden;
  }

  .assign-entities-list-data {
    height: calc(94vh - 218px);
    overflow-y: auto;
    padding: 0 20px 5px 20px;
  }

  .assign-entities-modal-search {
    margin: 0;
  }

  .assign-entities-list-header,
  .assign-entities-modal-search {
    max-width: 738px;
    width: 94%;
  }

  @media screen and (min-width: 768px) {
    .assign-entities-modal-body {
      padding: 15px 0;
    }
  }

  @media screen and (max-width: 767px) {
    height: 85vh;

    .assign-entities-modal-body {
      padding: 15px 0;
    }

    .assign-entities-list-header {
      top: calc(3vh + 142px);
    }

    .assign-entities-modal-search {
      margin: 0;
      top: calc(3vh + 63px);
    }

    .assign-entities-list-data {
      padding-top: 44px;
    }
  }
}

.assign-entities-section {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    padding-top: 55px;
  }

  .assign-entities-list-title {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    @media screen and (min-width: 768px) {
      border-bottom: 1px solid $light-grayish-cyan;
      border-top: 1px solid $light-grayish-cyan;
      height: 40px;
      margin: 0 -20px;
      padding: 0 20px;
    }

    .assign-entities-list-title-item {
      color: $grayish-blue;
      font-size: .875rem;

      @media screen and (min-width: 768px) {
        align-items: center;
        display: flex;
        width: calc(100% / 5);
      }
    }

    .assign-entities-list-title-item-name {
      @media screen and (min-width: 768px) {
        width: calc(100% / 5 + 120px);
      }
    }
  }

  .assign-entities-list-item {
    align-items: center;
    border-bottom: 1px solid $light-grayish-cyan;
    display: flex;
    position: relative;

    @media screen and (max-width: 767px) {
      height: 145px;
      padding: 15px;

      & > div {
        &:not(:last-child) {
          width: calc((100% - 24px) / 2);
        }
      }
    }

    @media screen and (min-width: 768px) {
      height: 40px;
    }

    .assign-entities-list-item-data {
      display: flex;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: space-between;
      }

      @media screen and (min-width: 768px) {
        width: 100%;
      }
    }

    .assign-entities-list-item-cell {
      color: $dark-gray;
      font-size: .875rem;

      @media screen and (min-width: 768px) {
        width: calc(100% / 5);
      }
    }

    .assign-entities-list-item-cell-grade,
    .assign-entities-list-item-cell-sis {
      @include ellipsis(100%);

      padding-right: 10px;
    }

    .assign-entities-list-item-cell-name {
      @include ellipsis(100%);

      padding-right: 10px;

      @media screen and (min-width: 768px) {
        width: calc(100% / 5 + 120px);
      }
    }

    .assign-entities-list-item-cell-email {
      @include ellipsis(100%);

      overflow: hidden;
      padding-right: 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .assign-entities-list-item-cell-email.staff {
      @media screen and (min-width: 768px) {
        overflow: visible;
      }
    }

    .assign-entities-list-item-button {
      margin-left: 15px;

      &.assign-entities-list-item-button--animated {
        svg {
          animation: check-icon 0.25s ease;
          transform: scale(1);
        }
      }

      @media screen and (min-width: 768px) {
        position: absolute;
        right: 0;
      }
    }
  }
}

@keyframes check-icon {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

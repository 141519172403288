@import "@classtag/ui/lib/colors";
@import "../../../../../styles/variables";

$box-shadow-dropdown: 0 0 8px 0 rgba(0, 0, 0, 0.25);
$border-color: #d7d7d7;
$item-background-color: rgba(32, 168, 255, 0.1);

.topbar-dropdown-menu {
  border: 0;
  border-radius: 8px;
  box-shadow: $box-shadow-dropdown;
  left: auto;
  overflow: hidden;
  padding: 0;
  right: 0;
  top: 45px;
  width: 330px;
  z-index: 991;

  @media screen and (max-width: 767px), screen and (max-height: 550px) {
    border-radius: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    overflow: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1002;

    &::after {
      background: $white;
      content: "";
      display: block;
      height: 100px;
      width: 100%;
    }

    @media screen and (max-width: 480px) {
      padding-bottom: 64px;
    }
  }
}

.topbar-dropdown-header {
  background-color: $ss-charcoal;
  border-top: 3px solid $ss-emerald-500;
  cursor: pointer;
  display: none;
  padding: 19px 18px;
  position: relative;

  &.topbar-dropdown-header--is-mobile {
    border-top: 0;
  }

  @media screen and (max-width: 767px), screen and (max-height: 550px) {
    display: block;

    svg {
      height: 12px;
      margin-bottom: -2px;
      margin-left: 5px;

      path {
        fill: $white;
      }

      & + span {
        left: 65px;
        position: absolute;
        top: 15px;
      }
    }
  }
}

.topbar-dropdown-item {
  border-radius: 0;
  cursor: pointer;
  display: flex;
  padding: 0 15px;
  transition: color 0.2s, background-color 0.2s;
  width: 100%;

  @media only screen and (min-device-width: 375px) and (max-device-width: 896px) and (orientation: landscape) {
    padding: 0 18px 0 35px;
  }

  &:hover {
    background-color: $ss-gray-100;
    transition: background-color 0.2s;
  }
}

.topbar-menu-item-content-wrap {
  display: flex;
  gap: 20px;
  padding: 15px 0;
  text-align: left;
  width: 100%;

  @media only screen and (min-device-width: 375px) and (max-device-width: 896px) and (orientation: landscape) {
    padding: 20px 0;
  }
}

.topbar-menu-item-description {
  width: calc(100% - 50px);

  p {
    color: $ss-gray-700;
    font-size: .875rem;
    line-height: 1.3125rem;
  }
}

.topbar-menu-item-icon-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30px;

  svg {
    path {
      transition: fill 0.2s;
    }
  }
}

.topbar-dropdown-item-title {
  color: $text-primary;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  margin: 0;

  .topbar-dropdown-item-subtitle {
    font-weight: normal;
  }

  &.topbar-dropdown-item-title--smore {
    align-items: center;
    display: flex;
    gap: 6px;

    .topbar-dropdown-item-subtitle {
      font-size: .75rem;
    }

    img {
      width: 80px;
    }
  }

}

.add-topbar-dropdown {

  @media (min-width: 768px) and (min-height: 551px) {
    .topbar-dropdown-toggle {
      position: relative;
      z-index: 3;
    }

    .topbar-right-dropdown-content {
      border: 0;
      box-shadow: none;
      right: 0;
      top: 0;

      & > div {
        &:first-child {
          border: 0;
        }
      }
    }
  }

  &.ctui-is-open {
    .topbar-dropdown-menu {
      display: block;
    }

    @media (min-width: 768px) and (min-height: 551px) {
      .topbar-right-dropdown-toggle {
        z-index: 100;
      }

      .js-navbar-dropdown-toggle {
        z-index: 100;
      }
    }
  }
}

@import '@classtag/ui/lib/colors';

.sign-in-wrapper {
  max-width: 395px;
  padding: 15px;
  width: 100%;
}

.sign-in-wrapper-title {
  color: $text-primary;
  font-weight: 600;
  margin: 0 0 14px;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

.sign-in-wrapper-sub-text {
  color: $text-secondary;
  font-size: 1rem;
  line-height: 24px;

  a {
    color: $link-color;
    margin-left: 4px;
    &:hover {
      color: $link-color-hover;
    }
  }
}

.sign-in-header-logo-wrap {
  margin-bottom: 20px;
  max-width: 255px;

  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
}

.sign-in-header-title {
  color: $text-primary;
  font-weight: 600;
  margin: 0 0 14px;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 36px;
  }
}

.sign-in-header-title-secondary {
  color: $text-primary;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 2px;
}

.sign-in-header-sub-text {
  color: $text-secondary;
  font-size: 1rem;
  line-height: 24px;
}

@import "@classtag/ui/lib/colors";
@import "../../../../styles/mixins";

$paste-cell-backround: rgba(94, 191, 105, 0.1);
$spreadsheet-overaly-transparent: rgba(255, 255, 255, 0);
$spreadsheet-overaly-semi-transparent: rgba(255, 255, 255, 0.5);
$spreadsheet-border-color: rgba(55, 191, 249, 1);

.copy-spreadsheet-modal {
  overflow-y: auto;

  .copy-from-spreadsheet-table-wrapper {
    padding-bottom: 220px;

    &.copy-from-spreadsheet-table-wrapper--expanded {
      padding-bottom: 245px;
    }
  }
}

.ct-spreadsheet {
  position: relative;

  .ct-paste-cell-value {
    color: $dark-gray;
    font-size: .75rem;

    & > div {
      color: $dark-gray;
      font-size: .75rem;
    }
  }

  .ct-paste-cell-value--success {
    background-color: $paste-cell-backround;
  }

  .ct-spreadsheet-credential {
    overflow: inherit !important;
    white-space: normal !important;
  }

  .ct-table_cell {
    height: 40px;
    min-height: 40px;
  }

  .paste-cell-value-language-wrap {
    div {
      color: $dark-gray;
      font-size: .75rem;
    }
  }

  .paste-cell-value-language {
    width: 100%;

    div {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .paste-cell-value-language-content {
    right: -11px;
    top: calc(100% + 7px);
    width: calc(100% + 22px);

    div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.ct-table_spreadsheet {
  border: 1px solid $light-grayish-cyan;
  word-break: break-all;

  &:not(.ct-table_spreadsheet_example) {
    td {
      color: $dark-gray;
    }

    thead {
      td {
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 767px) {
    td {
      &:not(.ct-table__td_index) {
        width: 200px;
      }
    }
  }

  thead {
    .ct-table_cell {
      font-weight: 600;
    }

    td {
      background-color: $light-grayish-blue;
    }
  }

  td {
    border-bottom: 1px solid $light-grayish-cyan;
    border-left: 1px solid $light-grayish-cyan;
    font-size: 12px;

    &:not(.ct-table__td_index) {
      width: 220px;
    }

    &:first-child {
      border-left: 0;
    }

    .ct-spreadsheet-credential {
      @include ellipsis(100%);
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .ct-select-locale-dropdown {
    display: block;
    float: none;
  }
}

.copy-from-spreadsheet-view {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: auto;

  .copy-spreadsheet-modal-container--mobile & {
    padding: 15px;
  }

  &.copy-from-spreadsheet-view-no-scroll {
    height: auto;

    .ct-spreadsheet {
      padding-bottom: 0;
    }

    .copy-from-spreadsheet-table-wrapper {
      height: auto;
    }
  }
}

.copy-from-spreadsheet-wrapper {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
}

.spreadsheet-page {
  &.progress-counter {
    font-weight: 600;
    left: 0;
    margin: (-30px) 0;
    max-width: 100%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 65%;
  }

  .loader {
    .ctui-loader-balls {
      left: calc(50% - 29px);
      position: absolute;
      top: calc(55% - 58px);
    }
  }
}

.copy-from-spreadsheet-message-error-row {
  align-items: center;
  display: flex;
}

.copy-from-spreadsheet-message-error {
  margin-bottom: 10px;
  word-break: break-all;

  p {
    color: $warning;
    font-size: .75rem;
    margin: 0 0 0 10px;
    width: calc(100% - 26px);
  }
}

.copy-from-spreadsheet-message {
  align-items: center;
  background: $white;
  box-sizing: border-box;
  display: flex;

  p {
    font-size: .875rem;
    line-height: 1.5625rem;
    margin: 0 0 0 10px;

    span {
      color: $navy;
    }

    a {
      color: $primary;
    }
  }
}

.copy-from-spreadsheet-header {
  .ctui-button {
    white-space: nowrap;
  }
}

td {
  &.paste-row-numeration {
    padding: 7px;
    text-align: center;
    width: 36px;
  }
}

.ct-spreadsheet-overlay {
  border: 1px solid $spreadsheet-overaly-transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: border 0.3s;

  &:hover {
    background-color: $spreadsheet-overaly-semi-transparent;
    border-color: $spreadsheet-border-color;
    transition: border 0.3s;

    &::after {
      color: $primary;
      content: "Paste Here";
      display: block;
      font-size: 1rem;
      left: 50%;
      margin: 0 auto;
      position: fixed;
      top: 285px;
    }
  }
}

.ct-spreadsheet__input {
  background: transparent;
  border: 0;
  caret-color: transparent;
  color: transparent;
  display: block;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 153px 50px;
  position: relative;
  text-shadow: none;
  width: 100%;
  z-index: 1;

  @media screen and (max-width: 767px) {
    padding: 102px 10px;
  }
}

.copy-spreadsheet-modal-container--mobile {
  .copy-from-spreadsheet-table-wrapper {
    .ct-table {
      tr {
        td {
          box-sizing: border-box;
          width: 200px;

          &:first-child {
            width: 40px;
          }
        }
      }
    }
  }
}

.ct-modal-freeze {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

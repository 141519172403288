@import "@classtag/ui/lib/colors";

.assign-entities-mobile-wrap {
  .assign-entities-modal-search {
    background: $white;
    margin: 0;
    max-width: 100%;
    top: 55px;
    z-index: 2;

    .search-input-clear {
      &.search-input-clear--visible {
        display: inline-flex;
      }
    }
  }

  .assign-entities-mobile-header {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $light-grayish-cyan;
    display: flex;
    font-size: 1rem;
    height: 55px;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 2;

    h3 {
      margin: 0;
    }
  }

  .assign-entities-mobile-link {
    align-items: center;
    display: flex;
    font-size: .875rem;
    justify-content: space-between;
    left: 20px;
    position: absolute;
    top: calc(50% - 16px);

    svg {
      margin-right: 10px;
    }
  }
}

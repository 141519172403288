.call-status-bar {
  align-items: center;
  background: linear-gradient(90deg, #3BC56A 0%, #229C4C 100%);
  color: #F3F4F6;
  display: flex;
  height: 65px;
  justify-content: space-between;
  left: 0;
  padding: 0 40px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 12000;

  &.call-status-bar--initiated {
    background: linear-gradient(90deg, rgba(59, 197, 106, 0.8) 0%, rgba(34, 156, 76, 0.8) 100%);
  }

  &.call-status-bar--finished {
    background: #565656;
  }
}

.call-status-bar-group-right {
  align-items: center;
  display: flex;
  gap: 30px;
  height: 100%;
}

.call-status-bar-actions {
  align-items: center;
  display: flex;
  gap: 20px;
  height: 100%;
}

.call-status-bar-button {
  align-items: center;
  border: 1px solid #98D27C;
  border-radius: 100px;
  color: #F3F4F6;
  display: flex;
  gap: 8px;
  padding: 8px 20px;
  width: 116px;

  span {
    width: 50px;
  }

  &.call-status-bar-button--is-active {
    background: #0F6F30;
  }

  .call-status-bar--finished & {
    border-color: #6E6F6E;
  }
}

.call-status-bar-separator {
  background: #E5E7EB;
  height: 21px;
  width: 1px;
}

.call-status-bar-call-button {
  align-items: center;
  background: #C83434;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 48px;
  justify-content: center;
  padding: 10px;
  width: 48px;
}

.call-status-bar-dialpad {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
}

.call-status-bar-user {
  align-items: center;
  display: flex;
}

.call-status-bar-user-avatar {
  margin-right: 7px;
}

.call-status-bar-user-name {
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.call-status-bar-user-status {
  margin-right: 5px;
}

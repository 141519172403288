@import '@classtag/ui/lib/colors';
@import '../../../../styles/mixins';

.sidebar-item-wrapper {
  align-items: center;
  display: flex;
}

.sidebar-item {
  align-items: center;
  color: $dark-gray;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  width: calc(100% - 44px);

  &:hover {
    .sidebar-item-image {
      background-color: $white;
    }

    i {
      color: $link-color-hover;
    }
  }

  &.sidebar-item--is-active {
    color: $primary;

    .sidebar-item-image {
      border-color: $primary;
    }

    i {
      color: $primary;
    }
  }

  &.sidebar-item--student {
    padding: 15px;
  }
}

.sidebar-item-avatar-wrap {
  height: 28px;
  margin-right: 10px;
  position: relative;
  width: 28px;

  .google-imported-status {
    bottom: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    top: unset;
  }
}

.sidebar-item-image {
  background-color: $light-grayish-blue;
  border: 2px solid $light-grayish-blue;
  border-radius: 50%;
  transition: background-color 0.35s;

  .loader-mini {
    margin: 12px auto;
    transform: scale(0.7);
  }

  .loader-mini-container {
    height: 16px;
  }
}

.sidebar-item-name {
  @include ellipsis(100%);
}

.sidebar-item-context-home-link {
  align-items: center;
  display: flex;
  min-height: 48px;
  padding: 10px 15px;

  i {
    color: $primary;
    font-size: .875rem;
  }
}

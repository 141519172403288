@import '@classtag/ui/lib/colors';

.privacy-policy-modal {
  &.ctui-modal {
    @media (min-width: 739px) {
      margin-left: -260px;
      max-width: 520px;
    }
  }

  .ctui-modal-header {
    h2 {
      padding: 0;
      width: 100%;
    }
  }

  .ctui-modal-footer {
    justify-content: center;
  }
}

.privacy-policy-modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.privacy-policy-modal-icon-wrap {
  height: 110px;
  margin-bottom: 15px;
  width: 110px;
}

.privacy-policy-modal-icon {
  height: 100%;
  width: 100%;
}

.privacy-policy-modal-info {
  text-align: center;
  width: 100%;
}

.privacy-policy-modal-action {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.privacy-policy-modal-checkbox {
  margin: 0;
}

.privacy-policy-modal-accept {
  min-width: 100px;
}

@import '@classtag/ui/lib/colors';

.connect-app-logo {
  align-items: center;
  color: $white;
  display: flex;
  font-size: 1.25rem;
  gap: 12px;

  .ct-navbar--styled & {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

@import '@classtag/ui/lib/colors';

.terms-of-use {
  color: $text-secondary;
  font-size: .75rem !important;
  line-height: 18px !important;
  text-align: left;

  a {
    color: $link-color;
    display: inline-block;

    &:hover {
      color: $link-color-hover;
    }
  }
}

@import '@classtag/ui/lib/colors';
@import '../../../../styles/variables';

.google-classroom-connection-toggle-button {
  background: $white;
  border-radius: 2px;
  box-shadow: $google-shadow;
  color: $google-color-light;
  font-size: .875rem;
  height: 2.5rem;
  justify-content: center;
  margin: 10px 0;
  min-width: 275px;
  width: 275px;

  &:focus,
  &:hover {
    background: $google-bg-light-hover;
    box-shadow: $google-shadow-hover-light;

    &:active {
      background: $google-bg-light-active;
      box-shadow: $google-shadow-hover-light;
    }
  }

  @media (max-width: 500px) {
    min-width: 100%;
    width: 100%;
  }
}

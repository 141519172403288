@import "@classtag/ui/lib/colors";
@import "../../styles/variables";

.library-container-header {
  & > div {
    border-bottom: 0;
  }
}

.library-header {
  border-bottom: 1px solid $light-grayish-cyan;
  line-height: 1.6em;
  padding: 15px 20px;

  h1 {
    color: $navy;
    font-weight: bold;
    margin: 10px 0;
  }
}

.library-body {
  padding: 0 20px 20px;
}

.library-section-header {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 0;
  padding: 10px 0 0;

  & + span {
    & .dropzone,
    & .dropzone:hover {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
}

.library-section-counter-wrapper {
  align-items: center;
  display: flex;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.library-section-counter {
  background-color: $light-grayish-blue;
  border-radius: 5px;
  font-size: 12px;
  margin-right: 7px;
  min-width: 24px;
  padding: 2px 7px;
}

.ctui-button {
  &.library-create-folder-button {
    font-size: 1rem;
    height: auto;
    margin-left: 15px;
  }
}

.library-folder {
  align-items: center;
  background: $white;
  border: 1px solid $silver;
  border-radius: 4px;
  color: $navy;
  display: flex;
  margin: 5px;
  padding: 15px;
  position: relative;
  width: calc(100% / 2 - 10px);

  &.library-folder--has-edit {
    .library-folder-label {
      width: calc(100% - 2.5rem);
    }

    &.library-folder--has-ding {
      .library-folder-label {
        width: calc(100% - 5.625rem);
      }
    }
  }

  &.library-folder--has-ding {
    .library-folder-label {
      width: calc(100% - 5rem);
    }
  }

  .library-folder-link {
    width: 100%;
  }

  .library-folder-wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .library-folder-icon-container {
    height: 16px;
    margin-right: 7px;
    text-align: left;
    width: 16px;
  }

  .folder-item-management {
    margin-left: 7px;
    position: absolute;
    right: 0;
  }

  .students-library & {
    width: calc(100% / 3 - 10px);
  }

  .icon-folder,
  .ctui-icon-backpack,
  .ctui-logo-google-classroom-round {
    margin-right: 7px;
  }

  .icon-folder {
    path {
      fill: $silver;
    }
  }

  .ding-notification {
    right: 8px;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% / 2 - 10px);

    .students-library & {
      width: calc(100% / 2 - 10px);
    }
  }

  @media screen and (max-width: 479px) {
    width: calc(100% - 10px);

    .students-library & {
      width: calc(100% - 10px);
    }
  }
}

.library-folder-label {
  overflow: hidden;
  width: 100%;

  p {
    color: $navy-darken;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.2s;
    white-space: nowrap;
    width: 100%;
  }

  &:hover {
    p {
      color: $primary;
      transition: color 0.2s;
    }
  }

  small {
    color: $navy-lighten;
    font-size: .6875rem;
  }
}

.library-section-body {
  margin: 0 -5px;
  padding: 10px 0;

  &.library-layout-tiles {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;

    .library-folder {
      position: relative;
    }
  }
}

.library-empty {
  color: $silver;
  font-size: 16px;
  padding: 40px 0;
  text-align: center;

  img {
    display: block;
    margin: 0 auto 15px;
    width: 180px;
  }
}

.library-root {
  .library-header {
    border-bottom: 1px solid transparent;
  }
}

.library-breadcrumbs {
  align-items: center;
  display: flex;
  font-size: 16px;

  svg {
    height: 12px;
    width: 16px;

    path {
      fill: $silver;
    }
  }
}

.library-breadcrumbs-home {
  span {
    color: $dark-gray;
    font-weight: 600;
    line-height: 1.4em;
  }
}

.library-section-empty {
  align-items: center;
  display: flex;
  padding: 20px 0;
}

.library-section-empty-info {
  @media (max-width: 374px) {
    height: 160px;
    position: relative;
  }
}

.library-section-empty-message {
  padding: 10px 0 15px;
}

.library-backpack-image-empty {
  margin: 0 50px;
  width: 80px;
}

.library-section-empty-button {
  &:hover {
    color: $white;
  }

  @media (max-width: 374px) {
    bottom: -20px;
    min-width: 290px;
    position: absolute;
    right: 50%;
    transform: translate(25%, 0);
    width: 290px;
  }
}

.library-backpack-description {
  max-width: 600px;
  padding: 15px 8px;

  strong {
    display: block;
    margin-bottom: 10px;
  }
}

.student-library {
  .library-body {
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  .library-section-empty,
  .library-empty {
    img {
      width: 140px;

      &.library-backpack-image-empty {
        margin: 0 35px;
        width: 70px;
      }
    }
  }
}

.multiselect-dropdown-toggle {
  .multiselect-dropdown-arrow {
    margin-left: auto;
    padding-left: 10px;
    position: relative;
    top: 1px;
  }

  .icon-chevron-down {
    path {
      fill: $silver;
    }
  }

  .dropdown-toggle {
    align-items: center;
    border: 1px solid $silver !important;
    border-radius: 5px;
    box-sizing: content-box;
    color: $navy-lighten;
    display: flex;
    height: 25px;
    margin-left: 7px !important;
    min-width: 160px;
    padding: 6px 8px 6px 6px;
    width: auto;

    &:focus,
    &:active {
      box-shadow: none;
      color: $navy-lighten;

      & > span {
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 335px) {
          max-width: 68px;
        }
      }
    }

    &:hover {
      color: $navy;
      opacity: 1;
    }

    & > span {
      background-color: $primary;
      border-radius: 4px;
      color: $white;
      margin-right: 4px;
      overflow: hidden;
      padding: 6px 8px;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 335px) {
        max-width: 68px;
      }

      &.filter-counter {
        background-color: $silver;
      }
    }
  }

  .dropdown-menu {
    border: 1px solid $silver;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    display: block;
    left: 7px;
    margin: 0;
    padding-bottom: 10px !important;
    right: 0;
    top: calc(100% - 1px);
    transition: opacity 0s linear;
    width: calc(100% - 7px);

    & > div {
      border: 0;
      box-shadow: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &.ctui-is-open {
    .dropdown-toggle {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      color: $navy-lighten;

      &:focus,
      &:active {
        box-shadow: none;
        color: $navy-lighten;
      }

      & > span {
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 335px) {
          max-width: 68px;
        }
      }
    }

    .icon-chevron-down {
      transform: scaleY(-1);
    }
  }
}

.library-uploader-cancel {
  margin-right: 10px;
}

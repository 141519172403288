@import "@classtag/ui/lib/colors";

$unsubscribe-bg-color: #f6f6f6;

.ct-unsubscribe {
  background: $unsubscribe-bg-color url("../../../assets/images/backgrounds/bg.jpg");
  height: 100vh;
  padding-top: 85px;
}

.ct-unsubscribe-header {
  align-items: center;
  background-color: $ss-charcoal;
  border-top: 3px solid $ss-emerald-500;
  display: flex;
  height: 61px;
  justify-content: center;
  left: 0;
  padding: 0 15px;
  position: absolute;
  right: 0;
  top: 0;

  .ct-logo-full {
    display: block;
  }
}

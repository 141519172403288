// Basic colors
$conversations-modal-z-index: 2147483000;
$white: #fff;
$green: #1f971c;
$purple: #b348ef;
$image-bg: rgba(51, 51, 51, 0.5);
$home-link: #774cb1;
$shadow-color: rgba(0, 0, 0, 0.2);

$bg-primary: #f6f8fa;
$bg-accent: #f2f5f9;

$overlay-bg: rgba(0, 0, 0, 0.6);

// Topbar
$topbar-height: 60px;
$topbar-height-mobile: 54px;
$topbar-district-bg-color: #1E2425;
$topbar-district-divider-color: #7f8ea5;
$topbar-school-bg-color: #1E2425;

// conversations
$conversation-background: #eff8ff;
$ct-conversations-modal-z-index: 2147483000;

// Comments
$comments-bg: #97c9f3;
$comments-bg-color: #f3faff;
$announcement-comments-list-bg: #f8fcff;

//Upload image dialog
$radial-grey: rgba(2, 0, 36, 0);
$radial-white: rgba(255, 255, 255, 0.6);

// Directory page
$directory-table-border: #c3c9d9;
$paste-overlap-table-label: rgba(158, 166, 191, 0.15);
$paste-overlap-table-label-open: rgba(158, 166, 191, 0.15);
$paste-overlap-table-item-bg: rgba(31, 168, 255, 0.1);

//connect/sign in buttons
$facebook-bg: #3579ea;
$ms-bg: #2f2f2f;
$apple-pressed: #bbb;
$classlink-bg: #35abcd;
$classlink-bg-hover: #2b92b0;
$clever-bg: #4274f6;
$clever-bg-hover: #355dc5;
$google-bg: #4285f4;
$google-bg-active: #3367d6;
$google-color-light: rgba(0, 0, 0, 0.54);
$google-bg-light-hover: #f9fafb;
$google-bg-light-active: #eee;
$google-shadow: 0 1px 1px rgba(0, 0, 0, 0.24), 0 0 1px rgba(0, 0, 0, 0.12);
$google-shadow-hover: 0 0 4px rgba(66, 133, 244, 1);
$google-shadow-hover-light: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
$google-bg-hover: #303030;

// Profile pages
$sections-border: #f2f2f7;

// App skeleton
$skeleton-light-color: #f3f2f8;
$skeleton-dark-color: #e7eaf0;

//dividers
$new-classroom-divider: #d4d4d4;

$safe-area-inset-bottom: env(safe-area-inset-bottom, 34px);

$link-color-legacy: #1561d2;
$link-color-legacy-hover: #074cb1;
$brand-secondary: #ff9c00;

@import '@classtag/ui/lib/colors';

.sign-in-header-links {
  display: flex;
  gap: 24px;
  position: absolute;
  right: 25px;
  top: 25px;

  @media screen and (min-width: 1441px) {
    right: 85px;
  }
}

.sign-in-header-link {
  color: $text-primary;
  font-size: 1rem;
  font-weight: 500;

  &:hover {
    color: $text-primary;
  }
}

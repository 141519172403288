@import "@classtag/ui/lib/colors";

.confirmation-modal,
.confirmation-modal-backdrop {
  z-index: 13000 !important;

  & + .ctui-modal-backdrop {
    z-index: 12999;
  }

  .ctui-modal-header-center {
    h2 {
      padding-left: 48px;
      padding-right: 0;
    }
  }

  .ctui-modal-footer {
    .confirmation-modal-button--cancel {
      margin-left: 0;
    }
  }

  .confirmation-modal-loader-bars {
    padding: 0;
  }
}

.confirmation-body-with-list {
  .confirmation-classes {
    margin: 0 -20px;
    padding: 0 20px;
  }

  .list {
    border-bottom: 0;
  }

  .list-body {
    & > div {
      border: 0;
      padding: 2px 0 0;

      &:last-child {
        padding: 13px 0 10px;
      }

      .profile-avatar {
        height: 37px;
        min-width: 37px;
        width: 37px;
      }

      .ct-user-profile-avatar {
        border: 1px solid $primary;
        height: 37px;
        min-width: 37px;
        width: 37px;
      }
    }
  }

  .icon-chevron-right {
    display: none;
  }
}

@import "@classtag/ui/lib/colors";

.user-account-topbar {
  align-items: center;
  display: flex;
  gap: 0;
  padding: 10px;

  .user-account-topbar--title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    word-wrap: normal;
  }

  .user-account-topbar-back-link {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 0 0 -10px;
    padding: 16px;
  }

  div {
    color: $white;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.8em;
    min-width: calc(100% - 74px);
    text-align: left;
  }
}

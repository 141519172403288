@import "@classtag/ui/lib/colors";

.directory-header-dropdown {
  margin-left: auto;
  padding: 3px 15px 0 10px;
  position: absolute;
  right: 0;

  .directory-header-dropdown-button-chevron-icon {
    transition: transform 0.3s;
  }

  &.ctui-is-open {
    .directory-header-dropdown-button-chevron-icon {
      transform: rotate(180deg);
    }
  }

  & > .directory-header-dropdown-content {
    padding-bottom: 70px;
    right: 15px;
  }
}

.directory-header-dropdown-button {
  height: 38px;
  padding: 0 7px;
  width: 69px;
}

.directory-header-dropdown-button-chevron-icon {
  margin-left: 10px;
}

.directory-header-dropdown-content {
  & > div {
    min-width: 290px;

    @media screen and (max-width: 767px) {
      min-width: 255px;
    }
  }

  & .google-classroom-connection-toggle-button {
    border: 0;
    box-shadow: none;

    &:hover {
      background: none;
      box-shadow: none;
    }
  }
}

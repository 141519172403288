@import "@classtag/ui/lib/colors";

.topbar-ding {
  background: $ss-charcoal;
  background: $ss-pea-500;
  border: 2px solid $ss-charcoal;
  border-radius: 50%;
  height: 16px;
  position: absolute;
  right: -6px;
  top: -4px;
  width: 16px;

  .topbar-right-dropdown-toggle & {
    left: 23px;
    right: unset;
  }

  .topbar-page-sidebar & {
    left: 21px;
    right: unset;
  }
}

.topbar-ding--is-styled {
  border-color: var(--primary-color);
}

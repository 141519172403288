@import "@classtag/ui/lib/colors";

.ct-session-error {
  background: $ss-danger-50;
  border: 1px solid $warning;
  border-radius: 4px;
  color: $warning;
  font-size: 12px;
  margin: 20px 0;
  padding: 10px;
  text-align: center;
}

.directory-tabs {
  padding: 5px 0 0;

  .ctui-tabs-buttons {
    height: 40px;

    @media screen and (max-width: 380px) {
      padding-left: 0;
    }

    .ctui-tab-button {
      margin-right: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media screen and (max-width: 380px) {
    .ctui-tab-button {
      &:not(:last-child) {
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@import '@classtag/ui/lib/colors';

.sign-in-back-button-wrap {
  display: flex;
  justify-content: center;
  padding: 10px 0 0;

  & > * {
    color: $primary;

    &:hover {
      color: $primary-darken;
    }
  }
}

.sign-in-back-button {
  font-weight: 600;
}

$image-bg: rgba(51, 51, 51, 0.5);

@mixin hideScrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin ellipsis($width) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin icon-hover($radius) {
  .ct-hover-image {
    align-items: center;
    background-color: $image-bg;
    border-radius: 50%;
    display: none;
    font-size: 30px;
    height: $radius;
    justify-content: center;
    line-height: $radius;
    width: $radius;
  }

  &:hover {
    cursor: pointer;

    .ct-hover-image {
      display: flex;
    }
  }
}

@mixin line-clamp($lines) {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

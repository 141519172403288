@import "@classtag/ui/lib/colors";

.dropzone {
  background: transparent;
  border: 0;
  min-height: 0;
  padding: 35px 0;

  .dz-form-value {
    display: none;
  }

  .dropzone-previews {
    position: relative;
    z-index: 0;

    .dz-clickable-zone {
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &.dropzone-sortable {
      .dz-preview {
        cursor: move;

        & > * {
          cursor: move !important;

          &.dz-remove {
            cursor: pointer !important;
          }
        }
      }
    }

    .dz-preview {
      margin-bottom: 35px;
      margin-top: 0;

      &:not(.dz-processing) {
        .dz-progress {
          animation: none;
        }
      }

      .dz-error-message {
        left: -30px;
        top: 0;
        width: 180px;

        @media (max-width: 1300px) {
          left: -15px;
          opacity: 1;
          width: 150px;
        }

        a {
          color: $white;
          cursor: pointer;
          text-decoration: underline;
        }

        span {
          font-size: 11px;
        }

        &::after {
          border-style: solid;
          border-top-color: $warning;
          border-width: 10px 10px 0 10px;
          height: 0;
          left: 80px;
          top: 100%;
          width: 0;

          @media (max-width: 1300px) {
            left: 65px;
          }
        }
      }

      .dz-image {
        img {
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 120px;
          width: 120px;
        }
      }

      &:hover {
        z-index: 30;

        .dz-image {
          position: relative;
          z-index: 1 !important;

          &::before {
            background-color: #000;
            content: "";
            height: 100%;
            left: 0;
            opacity: 0.6;
            position: absolute;
            top: 0;
            width: 100%;
          }

          img {
            -webkit-filter: none;
            filter: none;
            transform: none;
          }
        }
      }

      .dz-details {
        padding: 50px 10px 0;

        .dz-size,
        .dz-filename {
          span {
            background-color: transparent;
            color: #fff;
          }
        }

        .dz-size {
          margin-bottom: 8px;
        }

        .dz-filename {
          color: #fff;

          &:hover {
            span {
              background-color: rgba(0, 0, 0, 0.8);
            }
          }
        }

        &::before {
          color: #fff;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 22px;
        }
      }

      .dz-remove {
        bottom: -24px;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);
        width: 100%;
      }

      &.dz-file-preview {
        .dz-image {
          background: #a5a6a8;

          svg {
            margin: 18px auto 0;
          }
        }

        &.dz-complete {
          .dz-image {
            z-index: 21;

            &.dz-image-file {
              z-index: 19;
            }
          }
        }
      }

      &.dz-image-preview {
        background: transparent;
      }

      &.dz-file-preview,
      &.dz-image-preview {
        .dz-image {
          border-radius: 10px;
        }
      }
    }
  }

  .ctui-icon-mic {
    margin-right: 10px;
  }

  &.dropzone--error {
    border-color: $warning !important;
    margin-bottom: 0;
  }
}

.dropzone {
  border: 1px dashed #c3c9d9;
  border-radius: 4px;
  margin: 1px 0 16px 0;
  min-height: 0;
  padding: 35px 0;
  text-align: center;
  transition: border-color 0.3s;
  width: 100%;

  &.dropzone {
    border: 1px dashed #c3c9d9;
    min-height: 0;
    padding: 35px 0;

    .dz-message {
      color: #8c95ad;
      margin: 5px 0;
    }
  }

  .ct-photo-upload & {
    margin: 0;

    &:hover {
      margin: 0;
      padding: 34px 0;
    }
  }

  .documents-upload {
    position: relative;
  }

  input[type="submit"],
  .document-upload-cancel {
    display: none;
  }

  .image-preview {
    min-height: 105px;
    padding: 35px 25px;
    position: relative;

    .image-preview-item {
      float: left;
      height: 80px;
      margin: 1%;
      width: 80px;
    }
  }

  .description {
    color: #7f7f7f;

    a {
      border-radius: 4px;
      color: $primary;
      display: inline-block;
      font-size: inherit;
      height: 32px;
      line-height: 32px;
      position: relative;
      text-decoration: underline;
      text-transform: lowercase;
      top: -1px;
      vertical-align: middle;
      width: auto;
    }
  }

  &:not(.image-preview-holder-empty) {
    background-color: #f7f8fa;
  }

  &:hover,
  &.hover {
    border-color: $primary;
    transition: border-color 0.3s;
  }
}

.ct-dropzone_mobile {
  &.dropzone {
    border-color: $primary;
    color: $primary;
    margin: 0;
    padding: 10px;

    &:not(.image-preview-holder-empty) {
      background-color: #fff;
    }

    &:hover {
      padding: 10px;
    }
  }
}

.section-event-photo {
  padding-top: 10px;
}

.ct-document-upload-controls {
  margin-top: 15px;
  text-align: center;
}

.ct-drop-zone_for-small-screen {
  color: $primary;

  .ct-drop-zone_for-small-screen-icon-attachment {
    & > svg {
      margin-right: 5px;
    }
  }

  .ctui-icon-mic {
    margin-right: 5px;

    path {
      fill: $primary;
    }
  }
}

.ct-drop-zone_for-big-screen {
  a {
    &.ct-drop-zone_for-big-screen-link {
      position: sticky;
      vertical-align: unset;
    }
  }
}

.dropzone--disabled {
  pointer-events: none;
  position: relative;

  &::before {
    background-color: $white;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .dz-message,
  .dz-remove {
    display: none !important;
  }
}

// ToDo: cleanup

.forgot-password-form-wrap {
  @media screen and (min-width: 550px) {
    align-items: flex-end;
    display: flex;

    .forgot-password-form-input {
      width: 100%;
    }
  }

  button {
    font-size: .875rem;
    height: 38px;
    padding: 0 .625rem;

    @media screen and (min-width: 550px) {
      margin-left: 10px;
      max-width: 133px;
    }

    @media screen and (max-width: 549px) {
      margin-top: 15px;
    }
  }

  input {
    height: 38px;
    position: relative;

    @media screen and (min-width: 550px) {
      & + span {
        bottom: 7px;
        position: absolute;
      }
    }
  }
}

.ct-session-action-wrapper {
  padding: 20px 0;
}

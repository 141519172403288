.page-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px;

  @media screen and (max-width: 1024px) {
    .page-header-title,
    .page-header-filters {
      width: 50%;

      .page-context-chooser-dropdown,
      .page-context-chooser-dropdown-toggle {
        width: 100%;
      }
    }

    .page-header-filters {
      align-items: center;
      display: flex;
      justify-content: flex-end;
    }

    .page-header-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
    }
  }
}

.page-header-title {
  font-size: 1.125rem;
  font-weight: 600;
}

@import '@classtag/ui/lib/colors';

.assign-entities-modal-search {
  background-color: $white;
  border-bottom: 1px solid $light-grayish-cyan;
  margin: 0 -20px;
  max-width: 768px;
  padding: 20px;
  position: fixed;
  top: calc(3vh + 63px);
  width: 100%;
  z-index: 2;

  @media screen and (max-width: 767px) {
    top: 55px;
  }
}

@import "@classtag/ui/lib/colors";

.task-apply-holder {
  @media screen and (max-width: 991px) {
    & > div {
      font-size: 12px;
      padding-top: 10px;
    }

    & > input {
      font-size: 12px;
      line-height: 26px;
    }
  }
}

.activity-label-task-status {
  align-items: center;
  display: inline-flex;
  height: 38px;
  margin: 0;

  label {
    font-size: .75rem;
    font-weight: 400;
  }

  .activity-label-task-status-check-container {
    align-items: center;
    border: 1px solid $border-primary;
    border-radius: 3px;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 5px;
    width: 16px;

    &.activity-label-task-status-check-container--confirm {
      border-color: $primary;
    }
  }
}

.activity-label-task-status-btn {
  margin-left: 20px;
  margin-top: 0;
}

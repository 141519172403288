.context-switcher-notice {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 125px 30px;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    padding: 30px;
  }

  img {
    position: absolute;
    right: 60px;
    top: 30px;
    width: 66px;

    @media screen and (max-width: 1259px) {
      right: 30px;
    }

    @media screen and (max-width: 767px) {
      right: 15px;
      top: 15px;
      width: 50px;
    }
  }

  h4 {
    line-height: 1.6875rem;
    max-width: 500px;
    text-align: center;

    @media screen and (max-width: 1259px) {
      max-width: 74%;
    }

    @media screen and (max-width: 767px) {
      max-width: 85%;
      text-align: left;
    }
  }
}

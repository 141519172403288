@import "@classtag/ui/lib/colors";
@import "../../styles/mixins";

$input-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

.ct-create {
  .navbar-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .navbar-header-logo-container {
      width: calc(100% - 100px);

      .connect-app-logo {
        width: 100%;

        @media screen and (max-width: 559px) {
           svg {
            width: 100%;
          }
        }
      }
    }
  }

  .navbar-join {
    float: right;

    @media screen and (max-width: 767px) {
      margin-top: 5px;
    }
  }
}

.ct-create_narrow {
  .container {
    @media (min-width: 992px) {
      width: 760px;
    }
  }
}

.ct-create-header {
  background-color: $white;
  border-bottom: 1px solid $border-primary;
  left: 0;
  padding: 10px 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 31;

  .container {
    position: relative;
  }

  @media screen and (max-width: 767px) {
    .form-control {
      height: 32px;
    }
  }
}

.ct-create-header-container {
  align-items: center;
  display: flex;
}

.ct-create-header_left {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-height: 38px;
  width: calc(100% - 212px);

  @media screen and (max-width: 991px) {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    position: relative;
    text-align: center;
    width: calc(100% - 175px);
    z-index: 2;
  }

  @media screen and (max-width: 767px) {
    height: 32px;

    &.ct-create-header_left_single {
      padding-top: 0;
    }

    .ct-text_sm {
      display: none;
    }

    .ct-text_md {
      @include ellipsis(100%);

      line-height: 32px;
      margin: 0 auto;
      vertical-align: middle;

      &.announcement-header-title {
        @include ellipsis(calc(100% - 34px));
      }

      &.announcement-header-title--send-later {
        @include ellipsis(calc(100% - 60px));

        @media screen and (max-width: 480px) {
          margin-left: 10px;
        }
      }
    }
  }
}

.ct-create-header_right {
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;
  width: 212px;

  .layout-create-cancel-btn {
    margin-right: 10px;
  }

  @media screen and (max-width: 991px) {
    &:not(.ct-create-header--calendar &) {
      bottom: 0;
      display: flex;
      justify-content: space-between;
      left: 0;
      padding: 0 15px;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }

    .layout-create-submit-btn {
      z-index: 2;
    }

    .layout-create-submit-btn,
    .layout-create-cancel-btn,
    .btn-left,
    .btn-right {
      margin: 0;
      min-width: 62px;
      position: absolute;
      top: 1px;
    }

    .layout-create-submit-btn,
    .btn-right {
      right: 15px;

      .ct-create-header--calendar & {
        right: 0;
      }
    }

    .layout-create-cancel-btn,
    .btn-left {
      left: 15px;
    }

    .ct-create_announcement & {
      width: 100%;
    }
  }
}

.ct-create-body {
  padding: 70px 0 20px;

  @media screen and (max-width: 767px) {
    padding-top: 59px;
  }

  &.ct-create-body--block-editor {
    padding-bottom: 0;
    padding-top: 60px;

    .container {
      height: calc(100vh - 61px);
      padding: 0;
      width: 100%;

      & > div {
        height: 100%;
      }
    }
  }
}

.create-success-header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: 38px;
  position: relative;

  @media (max-width: 1199px) {
    .layout-create-submit-btn {
      &:not(:disabled) {
        &:hover {
          background-color: $primary;
          border-color: $primary;
        }
      }
    }

    .layout-create-cancel-btn {
      &:not(:disabled) {
        &:hover {
          background-color: transparent;
          border-color: $silver;
          color: $navy;
        }
      }
    }
  }

  @media (min-width: 992px) {
    max-width: 760px;
  }
}

.create-success-header-text {
  font-size: 16px;
  font-weight: 600;
}

.create-success-header-action-btn {
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (max-width: 767px) {
    top: 3px;
  }
}

.ct-create-preview-event-time {
  width: 50%;
}

.ct-create-event-notification-time {
  align-items: center;
  display: flex;
  gap: 5px;
}

.ct-create-event-notification-time:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.ct-create-event-success__img,
.create-ptc-success-img {
  background-image: url("../../assets/success_illustration_2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 139px 145px;
  height: 145px;
  margin: 10px auto;
  width: 139px;
}

.create-ptc-success-img {
  background-image: url("../../assets/success_ptc_2x.png");
}

@media screen and (max-width: 767px) {
  .ct-create-event-success__img,
  .create-ptc-success-img {
    background-size: auto 100px;
    height: 100px;
    margin-top: 0;
  }
}

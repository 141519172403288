@import "@classtag/ui/lib/colors";

.session-user {
  align-items: center;
  display: flex;
  gap: 12px;
  width: 100%;

  .topbar-user-dropdown-content & {
    border-bottom: 1px solid $border-primary;
    padding: 8px 16px;
  }
}

.session-user-name {
  flex-grow: 1;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 40px - 36px - 24px);
  word-break: break-all;
  word-wrap: normal;
}

.session-user-settings-link {
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:focus {
    box-shadow: 0 0 0 4px $ss-gray-900;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}

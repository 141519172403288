@import "@classtag/ui/lib/colors";

.directory-list {
  &.list {
    border: 0;
    padding: 0;
  }

  .list-refetch-loader {
    margin: 15px 0 20px;
  }

  .directory-list--body {
    & > div {
      padding: 0;
    }

    .profile-entity-cell {
      padding: 0 15px 0 0;
    }
  }
}

.no-students-message {
  color: $silver-darken;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.directory-container--classroom {
  .ct-user-profile-avatar {
    border: 1px solid $border-primary;
  }
}

.no-requests-message {
  color: $silver-darken;
  padding: 25px 15px;
}

.send-invitations-link {
  display: flex !important;

  svg {
    path {
      fill: $silver;
    }
  }
}

@import "@classtag/ui/lib/colors";
@import "../../../../../styles/mixins";

.paste-spreadsheet-modal-header-action {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 20px;

  @media screen and (max-width: 767px) {
    button {
      height: 2rem;
    }
  }
}

.paste-spreadsheet-modal-header-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 0;
  width: 100%;
}

.paste-spreadsheet-modal-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  max-width: 1180px;
  padding: 0 20px;
  width: 100%;

  .modal-full-screen-header {
    @include ellipsis(100%);

    color: $navy-darken;
    font-size: 1rem;
    font-weight: 600;
    width: fit-content;
  }

  .modal-full-screen-header-cancel {
    align-items: center;
    display: flex;
  }

  h3 {
    margin: 0;
    padding-left: 0;
    padding-right: 15px;
    text-align: left;
  }
}

@import "@classtag/ui/lib/colors";

.google-imported-status {
  background: $google-import-bg-color;
  border: 1px solid $google-import-border-color;
  border-radius: 50%;
  height: 8px;
  position: absolute;
  right: 0;
  top: 0;
  width: 8px;
}

.send-invitations-now {
  .send-invitations-chooser-info {
    font-weight: 600;
  }

  .ctui-modal-footer {
    button {
      width: auto;
    }
  }
}

.send-invitation-now-checkbox {
  margin: 14px 0;

  label {
    span {
      margin-right: 7px;
    }
  }
}

@import '@classtag/ui/lib/colors';
@import '../../../styles/mixins';

$sidebar-overlay: rgba(255, 255, 255, 0.7);
$sidebar-shadow: rgba(0, 0, 0, 0.25);
$sidebar-classroom-border: rgba(56, 191, 247, 0);

.sidebar-container {
  bottom: 0;
  box-shadow: 0 0 5px 0 $sidebar-shadow;
  left: 0;
  position: fixed;
  top: 61px;
  transform: translateX(100vh);
  transition: 0.3s ease-in;
  width: 240px;
  z-index: 6001;

  &.sidebar-container--is-open {
    transform: translateX(0);
  }

  @media screen and (max-width: 1024px) {
    box-shadow: none;
    transform: translateX(100%);
    width: 100%;
  }
}

.sidebar {
  background: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.sidebar-header {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $border-primary;
  border-top: 0;
  color: $text-primary;
  display: flex;
  flex-basis: 61px;
  flex-shrink: 0;
  height: 61px;
  order: 1;
  padding: 0 8px 0 15px;
  text-align: left;
  width: 100%;

  .user-thumbnail {
    width: calc(100% - 40px);
  }
}

.sidebar-student-avatar {
  margin-bottom: 5px;
  margin-right: 15px;
}

.sidebar-content {
  @include hideScrollbar;
  flex: 1;
  order: 2;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .ctui-collapsible {
    border-bottom: 1px solid $border-primary;

    .ctui-collapsible-tab-name {
      font-weight: 600;
    }
  }
}

.sidebar-close {
  background-color: #333539;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0;
  border-top: 0;
  cursor: pointer;
  height: 61px;
  position: absolute;
  right: 0;
  top: 0;
  transition: right 0.3s;
  width: 65px;

  @media (min-width: 1025px) {
    .sidebar-container--is-open & {
      right: -65px;
    }
  }
}

.sidebar-collapsible-tab {
  padding: 10px 0 10px 15px;
}

.sidebar-classroom-archived {
  .sidebar-classroom-image {
    border-color: $border-primary;

    &::after {
      border-left-color: $border-primary;
    }
  }
}

.sidebar-help-item {
  padding-top: 0;

  a {
    border-top: 1px solid $border-primary;
    padding-top: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .sidebar-classroom-new {
    &.desktop {
      display: none;
    }

    &.tablet {
      display: block;

      & + .popover {
        left: 40px !important;

        .arrow {
          left: 31px !important;
        }
      }
    }
  }
}

.sidebar-header-user {
  align-content: stretch;
  display: flex;
  order: 2;
  width: calc(100% - 100px);

  img {
    border: 2px solid $white;
    margin-right: 10px;
  }
}

.classroom-logout {
  button {
    font-size: .875rem !important;
  }
}

@import "@classtag/ui/lib/colors";

.list-refetch-loader {
  margin: 0 15px;
  padding: 20px 0;

  .loader-mini {
    margin: 0;
  }

  span {
    color: $silver;
    display: block;
    text-align: center;
  }
}

@import '@classtag/ui/lib/colors';

.sing-in-layout-wrapper {
  display: grid;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    background-color: $ss-forest-600;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 320px 1fr;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: 485px 1fr;
  }
}

.sing-in-layout-wrapper-body {
  align-items: flex-start;
  background-color: $ss-forest-600;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 30px;
  }
  @media screen and (min-width: 992px) {
    padding: 32px 48px 96px;
  }

  h4 {
    color: $ss-lime-300;
  }

  p {
    color: $text-primary-dark;
    font-size: 1rem;
    line-height: 24px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  a {
    color: $link-color-lighten;
    font-size: 1rem;
    line-height: 24px;
    margin: 0 4px;
  }
}

.sing-in-layout-wrapper-header {
  display: flex;
  flex-direction: column;
  gap: 144px;
}

.sing-in-layout-wrapper-logo-wrap {
  align-items: center;
  background: transparent;
  color: $white;
  column-gap: 9px;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 14px;
  padding: 0;

  &:hover {
    color: $white;
  }

  svg {
    height: 21px;
    width: 100%;
  }

  span {
    margin-bottom: -3px;
  }
}

.sing-in-layout-wrapper-illustration {
  max-width: 405px;
  width: 100%;
}

.sing-in-layout-wrapper-content {
  align-items: center;
  display: flex;
  padding: 20px 34px 60px;
  position: relative;

  @media screen and (max-width: 359px) {
    padding: 20px 15px 50px;
  }

  @media screen and (min-width: 768px) {
    padding: 25px 25px 50px;
  }

  @media screen and (min-width: 1441px) {
    padding: 25px 85px 25px;
  }
}

.sing-in-layout-wrapper-content-card {
  border-radius: 10px;
  margin: 0;
}

.sing-in-layout-wrapper-footer {
  bottom: 20px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: max-content;
  z-index: 0;

  @media screen and (min-width: 768px) {
    left: 215px;
    z-index: -1;

    .enrollment-body & {
      left: 245px;
    }
  }

  @media screen and (min-width: 1100px) {
    .enrollment-body & {
      left: 280px;
    }
  }

  @media screen and (min-width: 1441px) {
    left: 280px;

    .enrollment-body & {
      left: 345px;
    }
  }
}

.copyright {
  color: $white;
  font-size: 10px;
  opacity: 0.5;
  text-align: center;

  @media screen and (min-width: 768px) {
    color: $black;
    font-size: 12px;
    z-index: -1;
  }
}

@import "@classtag/ui/lib/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

$namesakes-dropdown-shadow: (-2px) -2px 5px 0 rgba(0, 0, 0, 0.13);

.paste-overlap-table {
  background-color: $white;
  border-collapse: separate;
  width: 100%;

  @media (max-width: 640px) {
    max-width: 600px;
  }

  tr {
    th,
    td {
      border-bottom: 1px solid $directory-table-border;
      border-right: 1px solid $directory-table-border;
      padding-left: 20px;
      padding-right: 20px;

      &:first-child {
        border-left: 1px solid $directory-table-border;
      }
    }

    th {
      border-top: 1px solid $directory-table-border;
    }

    &:first-child {
      th {
        &:first-child {
          border-top-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
        }
      }
    }

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }

    &.paste-overlap-table-item-namesakes {
      background: $paste-overlap-table-item-bg;

      td {
        max-width: 116px;

        &:last-child {
          padding: 0;
        }
      }

      .paste-overlap-table-item-wrap {
        color: $ss-gray-800;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &:not(.paste-overlap-table-item-namesakes) {
      h3,
      p {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
      }

      p {
        &::before {
          content: "(";
        }

        &::after {
          content: ")";
        }
      }
    }

    .paste-overlap-table-item-parents {
      display: block;
    }
  }

  td {
    color: $ss-gray-800;
    font-size: .875rem;
    font-weight: normal;
    line-height: 19px;
    padding: 20px;
    position: relative;

    &:first-child {
      padding: 0;
      text-align: center;
    }

    &:nth-child(2),
    &:nth-child(3) {
      &::after {
        background: $white;
        border: 1px solid $directory-table-border;
        border-radius: 100%;
        bottom: 0;
        box-sizing: border-box;
        color: $directory-table-border;
        content: "›";
        display: inline-block;
        font-size: 1.25rem;
        height: 22px;
        left: 0;
        line-height: 17px;
        margin: auto;
        position: absolute;
        right: -100%;
        text-align: center;
        top: 0;
        width: 22px;
      }
    }
  }

  th {
    color: $ss-gray-800;
    font-size: 1rem;
    font-weight: 600;
    height: 52px;
    line-height: 22px;
    text-align: center;
  }

  h4 {
    color: $ss-gray-800;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 3px;
    margin-top: 0;
  }

  p {
    line-height: 1.3;
  }

  .ct-checkbox__label {
    padding: 5px 0;
  }

  .three-dots {
    @include ellipsis(100%);
  }

  .paste-overlap-table-number {
    min-width: 50px;
    width: 5%;
  }

  .paste-overlap-table-students {
    width: 30%;
  }

  .paste-overlap-table-actions {
    width: 25%;
  }

  .paste-overlap-table-namesakes {
    width: 35%;
  }
}

.paste-overlap-table-item-wrap {
  width: 100%;

  p {
    font-size: .875rem;
    margin: 0;
  }
}

.paste-overlap-table-item-label {
  align-items: center;
  background: $paste-overlap-table-label;
  border-radius: 4px;
  color: $ss-gray-800;
  display: inline-flex;
  font-size: .75rem !important;
  font-weight: normal;
  height: 22px;
  margin-left: 5px;
  padding: 0 9px;
}

.paste-overlap-table-item-last-child {
  font-size: .875rem;
  padding: 6px 20px;
  white-space: normal;

  .ctui-modal & {
    font-size: .875rem;
    padding: 6px 20px;
  }
}

.paste-overlap-notification {
  background: $white;
  border: 1px solid $primary;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;

  svg {
    font-size: 1.125rem;
    margin-right: 10px;
    vertical-align: top;
  }

  h2 {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
  }

  p {
    margin-bottom: 5px;
  }
}

.namesakes-dropdown-warning {
  color: $ss-gray-700;
  font-size: .8125rem;
  margin-top: 10px;
  white-space: normal;

  .ctui-modal & {
    color: $ss-gray-700;
    font-size: .8125rem;
    margin-top: 10px;
    white-space: normal;
  }
}

.namesakes-dropdown-icon-wrap {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.namesakes-dropdown {
  height: 100%;
  padding: 20px;
  white-space: break-spaces;
  width: 100%;

  .namesakes-dropdown-toggle-text-wrap {
    text-align: left;
  }

  .namesakes-dropdown-toggle-icon-wrap {
    margin-left: 10px;
    transition: transform 250px;
  }

  .namesakes-dropdown-content {
    right: -1px;
  }

  &.ctui-is-open {
    background: $white;

    .paste-overlap-table-item-label {
      background: $paste-overlap-table-label-open;
    }

    .namesakes-dropdown-toggle-icon-wrap {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

.namesakes-dropdown-toggle {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
}

.namesakes-dropdown-content {
  width: calc(100% + 2px);

  & > div {
    border: 1px solid $directory-table-border;
    border-radius: 0;
    box-shadow: none;
  }
}

.namesakes-dropdown-content-list {
  ul {
    margin: 0;
  }

  li {
    & > button {
      height: 100%;
      padding: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $directory-table-border;
    }
  }
}

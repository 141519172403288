@import "@classtag/ui/lib/colors";

.directory-modal {
  p {
    &.error-message {
      color: $warning;
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  .ct-card {
    margin-bottom: 0;
  }

  .directory-modal-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 400px) {
      flex-direction: column;

      & > div {
        width: 100%;

        & + div,
        & + .ctui-button {
          margin-top: 15px;
        }
      }
    }

    &.directory-modal-footer-single {
      justify-content: flex-end;
    }

    .ctui-button {
      min-width: 100px;
      width: auto;

      @media (max-width: 768px) {
        & > div {
          align-items: center;
          display: flex;
        }
      }

      @media (max-width: 400px) {
        margin-left: 0;
        min-width: 100%;
        width: 100%;

        & + .ctui-button {
          margin-top: 15px;
        }
      }
    }

    .ctui-button--secondary {
      margin-left: 0;

      & + .ctui-button--secondary {
        margin-left: 15px;

        @media (max-width: 400px) {
          margin-left: 0;
        }
      }

      &.ct-add-parent_preview {
        margin-left: 0;
      }
    }
  }

  .profile-personal-information,
  .list {
    .ctui-modal-body & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.student-profile-actions {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
}

@import '@classtag/ui/lib/colors';

.check-invitation-success-modal {
  @media screen and (min-width: 739px) {
    margin-left: (-250px) !important;
    max-width: 500px !important;
  }

  .ctui-modal-body {
    padding: 0 20px;

    @media screen and (min-width: 480px) {
      padding: 0 40px;
    }

    p {
      text-align: center;
    }

    .ct-resend-confirmation-button-wrapper {
      padding: 20px 50px 30px;

      .error-message {
        color: $warning;
        margin-bottom: 10px;
        text-align: center;
      }
      @media screen and (max-width: 479px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .ct-resend-confirmation-button {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  .ct-icon-lock-check {
    margin: 30px auto 10px;
  }
}

.ct-icon-lock-check {
  background-image: url("../../assets/lock-check.svg");
  background-repeat: no-repeat;
  background-size: 120px;
  height: 120px;
  margin: auto;
  width: 120px;

  @media screen and (max-width: 479px) {
    background-size: 80px;
    height: 80px;
    width: 80px;
  }
}

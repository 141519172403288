@import "@classtag/ui/lib/colors";

.modal-notice {
  background: $light-grayish-blue;
  border-bottom: 1px solid $light-grayish-cyan;
  display: block;
  font-size: 1rem;
  padding: 10px 20px;
  text-transform: none;

  .modal-notice-text {
    display: inline;

    p {
      color: $text-secondary;
      display: inline;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .modal-notice-learn-more {
    color: $primary;
    font-size: 1.4rem;
    margin-left: 5px;
  }

  &.modal-notice-corners-rounded-bottom {
    border-radius: 0 0 10px 10px;
  }
}

.new-leader-form-permissions {
  margin-top: 20px;

  .ctui-divider {
    margin:  30px 0;
  }

  h4 {
    font-size: 1rem;
    margin-bottom: 15px;
  }
}

@import "@classtag/ui/lib/colors";

.session-nav--border {
  border-bottom: 1px solid $border-primary;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  & > a, button {
    align-items: center;
    color: $text-primary;
    display: flex;
    font-size: .875rem;
    justify-content: flex-start;
    padding: 8px 20px;
    position: relative;
    text-align: left;
  }
}

.session-nav--log-out,
.session-nav--admin {
  a,
  button {
    color: $warning;
    display: block;
    font-size: .875rem;
    padding: 16px 20px;
    text-align: left;
    width: 100%;

    @media screen and (max-width: 600px) {
      font-size: .875rem;
    }
  }
}

.session-nav--admin {
  border-bottom: 1px solid $border-primary;
}

.unread-notifications-count {
  background: $ss-emerald-500;
  border-radius: 24px;
  color: $white;
  font-size: .75rem;
  height: 20px;
  line-height: 20px;
  min-width: 24px;
  padding: 0 7px;
  position: absolute;
  right: 15px;
  text-align: center;
  top: calc(50% - 10px);
}

.session-nav--switch-user {
  max-height: 35vh;
  overflow-y: auto;
}

.add-student-footer {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  @media (max-width: 650px) {
    flex-wrap: wrap;

    .ctui-button {
      margin-left: 0;
    }
  }
}

.add-student-additional-actions,
.add-student-form-actions {
  display: flex;

  .ctui-button {
    svg {
      height: 28px;
      margin-right: 5px;
    }
  }
}

.add-student-additional-actions {
  flex-grow: 1;

  @media (max-width: 1023px) {
    flex-direction: column;

    .ctui-modal & {
      flex-direction: unset;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    .ctui-modal & {
      flex-direction: column;
    }
  }

  .ctui-button {
    max-width: 320px;
    width: auto;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: 1023px) {
      margin-bottom: 10px;
    }

    @media (max-width: 767px) {
      svg {
        height: 22px;
      }
    }

    @media (max-width: 650px) {
      max-width: 100%;
      width: 100%;

      .ctui-modal-header & {
        width: auto;
      }
    }

    .ctui-modal & {
      @media (max-width: 1023px) {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
  }
}

.add-student-form-actions {
  flex-grow: 2;
  justify-content: flex-end;

  @media(max-width: 650px) {
    margin-top: 15px;
    width: 100%;

    .ctui-button {
      width: 100% !important;
    }
  }
}

.add-student-form-input {
  margin: 15px 0;
}

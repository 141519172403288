@import '@classtag/ui/lib/colors';

.topbar-user-support-dropdown {
  margin: 0 24px 0 4px;

  svg {
    transform: rotate(0deg);
    transition: transform 150ms ease-in-out;
  }

  &.ctui-is-open {
    svg {
      transform: rotate(180deg);
    }
  }
}

.topbar-user-support-dropdown-toggle {
  gap: 8px;
  height: 40px;

  .ct-navbar--styled & {
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.topbar-user-support-dropdown-content {
  margin-top: 5px;
  min-width: 245px;
  padding: 2px 0;

  a,
  button {
    align-items: center;
    color: $text-primary;
    display: flex;
    font-size: .875rem;
    height: 38px;
    justify-content: flex-start;
    padding: 8px 16px;
    position: relative;
    text-align: left;
    width: 100%;

    &:first-child {
      margin-top: 2px;
    }

    &:last-child {
      margin-bottom: 2px;
    }
  }
}

.edit-password-form-wrap {
  input {
    height: 38px;
    margin-bottom: 15px;

    & + span {
      margin-bottom: 5px;
      margin-top: -10px;
      padding-top: 0;
    }
  }

  button {
    font-size: .875rem;
    height: 38px;
  }
}

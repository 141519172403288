@import '@classtag/ui/lib/colors';

.layout-public {
  background: $white;

  &.controller-sessions {
    &.action-new {
      &.breast-cancer-awareness-month {
        background-image: url('../images/breast_cancer_month.png');
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 50%;

        @media screen and (max-width: 767px) {
          background-size: 100%;
        }
      }
    }
  }
}

.layout-public-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

@media screen and (max-width: 768px), screen and (max-height: 550px) {
  #cz_success_center_container {
    display: none !important;
  }
}
.controller-interaction-logs {
  #cz_success_center_container {
    display: none !important;
  }
}

.conversations-layout {
  #cz_success_center_container {
    display: none !important;
  }
}

.cz_success_center_container--is-hidden {
  #cz_success_center_container {
    display: none !important;
  }
}

.layout--integrations {
  #cz_success_center_container {
    display: none !important;
  }
}

.dialpad-dropdown {
  background: #171717;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 40px;
  position: absolute;
  right: 0;
  top: 100%;
}

.dialpad-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dialpad-row {
  align-items: center;
  display: flex;
  gap: 20px;
}

.dialpad-item {
  align-items: center;
  background: #333333;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: 75px;
  justify-content: center;
  width: 75px;
}

.dialpad-item-number {
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
}

.dialpad-item-letter {
  font-size: 11px;
  text-transform: uppercase;
}

@import "@classtag/ui/lib/colors";

.entities-list-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 80px;

  p {
    max-width: 460px;
    padding: 0 20px;
    text-align: center;
  }
}

.assign-entity-list-wrap {
  @media screen and (min-width: 768px) {
    padding-top: 142px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 123px;
  }
}

.assign-entities-list-header {
  background-color: $white;
  max-width: 768px;
  padding: 0 20px;
  position: fixed;
  top: calc(3vh + 142px);
  z-index: 2;

  @media screen and (max-width: 767px) {
    min-width: 100%;
    top: 134px;

    button {
      font-size: .875rem;
    }
  }

  @media screen and (min-width: 768px) {
    left: 50%;
    margin-left: -369px;
  }
}

.entities-list-header-counter {
  align-items: center;
  display: flex;
  height: 38px;
  justify-content: space-between;
  margin: 0 -20px;
  padding: 0 20px;

  p {
    font-weight: 600;
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    border-bottom: 1px solid $light-grayish-cyan;
    height: 44px;
    padding: 0 20px;
  }
}


@import "@classtag/ui/lib/colors";
@import '../../../styles/mixins';

.page-context-chooser-dropdown-wrapper {
  align-items: center;
  display: flex;
  gap: 5px;
  text-align: left;
  width: calc(100% - 12px);

  .google-imported-status {
    position: relative;
  }
}

.page-context-chooser-dropdown-content {
  @include hideScrollbar;
  border-radius: 8px;
  color: $text-primary;
  max-height: 50vh;
  overflow-y: scroll;
  top: 45px;
  width: 256px;

  &::-webkit-scrollbar {
    display: none;
  }

  .ctui-dropdown__content {
    .ctui-collapsible {
      &:last-child {
        .ctui-collapsible-content {
          border-bottom: 0;
        }
      }
    }
  }
}

.page-context-chooser-dropdown-context-name {
  font-size: .875rem !important;
  margin-right: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.page-context-chooser-dropdown-toggle {
  align-items: center;
  background: $ss-gray-100;
  border: 1px solid $ss-gray-500;
  border-radius: 99px;
  display: flex;
  height: 38px;
  justify-content: space-between;
  padding: 0 16px;
  width: 256px;

  &:focus {
    box-shadow: 0 0 0 4px $ss-gray-200;
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .ct-navbar--styled & {
    background: rgba(51,53,57,.5);
    border-color: rgba(101,104,106,.5);
  }
}

@import "@classtag/ui/lib/colors";

$transparent-bg: rgba(0, 0, 0, 0);

//for safari

.form-control-radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  left: -1000px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  z-index: -1000;

  + .form-control-radio-label {
    align-items: center;
    color: $dark-gray;
    cursor: pointer;
    display: inline-flex;
    font-size: .875rem;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 0;
    position: relative;

    &::before {
      background: $white;
      border: 1px solid $light-grayish-cyan;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 18px;
      margin-right: 5px;
      min-width: 18px;
      width: 18px;
    }

    &::after {
      background: transparent;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 8px;
      left: 5px;
      min-width: 8px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 8px;
    }
  }

  &:checked {
    + .form-control-radio-label {
      &::before {
        border-color: $primary;
      }

      &::after {
        background: $primary;
      }
    }
  }

  &:disabled {
    + .form-control-radio-label {
      color: $dark-gray-lighten;
      cursor: none;
      pointer-events: none;

      &::before {
        border-color: $light-grayish-cyan;
      }

      &::after {
        background: $transparent-bg;
      }
    }
  }
}

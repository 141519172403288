@import '@classtag/ui/lib/colors';
@import "../../styles/mixins";

$image-bg: rgba(51, 51, 51, 0.5);

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  overflow-x: hidden;
}

body {
  background-color: $light-grayish-blue;
  font-weight: 400;

  &:not(.ct-body-li) {
    height: auto;
    min-height: 100%;
  }

  & > main {
    padding-bottom: 220px;
    padding-top: 107px;
  }

  &.layout-create {
    background-color: $white;
    background-image: none;
  }

  &#admin {
    background-image: none;
  }

  &.spreadsheet-mobile {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 767px) {
  body {
    &:not(.ct-body-li) {
      & > main {
        padding-top: 57px;
      }
    }
  }
}

textarea {
  max-height: 200px;
  resize: none;
}

p {
  margin: 0 0 10px;
}

.error-text {
  color: $warning;
  font-size: 12px;

  .ctui-modal & {
    color: $warning;
    font-size: 12px;
  }
}

video {
  &::-webkit-media-controls-start-playback-button {
    display: none;
  }
}

.video-js {
  &.vjs-controls-enabled {
    .vjs-big-play-button {
      display: none;
    }

    &.vjs-paused {
      .vjs-big-play-button {
        display: block;
      }
    }
  }
}

input:-webkit-autofill {
  box-shadow: 0 0 0 50px #fff inset !important;
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0 50px #fff inset !important;
  -webkit-text-fill-color: #333;
}

.btn-mobile {
  @media screen and (max-width: 768px) {
    font-size: .6875rem;
    padding: 9px;
  }
}

// Wysiwyg global styles
.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.text-center {
  text-align: center;
}

.is-disabled-link {
  opacity: .5;
  pointer-events: none;
  & > * {
    pointer-events: none;
  }
}

#animation_container {
  left: 50%;
  position: fixed;
  top: 0;

  .school-supplies-landing &,
  .layout-create & {
    display: none;
  }
}

.ct-body-li {
  margin: 0;
  padding: 0;

  &.ios-app-gap-handler {
    min-height: 100vh;
  }

  .container,
  .sticky-container {
    width: 768px;

    .container {
      padding: 0;
      width: 100%;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
      width: 600px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    @media screen and (min-width: 1260px) {
      width: 798px;
    }
  }

  &.school-supplies-landing {
    background: $white;
    overflow-x: hidden;
  }

  &.conversations-layout {
    background: $white;
  }

  &:not(.conversations-layout) {
    background: var(--bg-color);
    background-attachment: fixed;
    background-blend-mode: var(--bg-blend-mode);
    background-image: var(--bg);
    min-height: 100vh;

    &::before {
      background: var(--bg-attachment);
      background-size: 1440px;
      content: '';
      display: block;
      height: var(--bg-attachment-height);
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }

    &.school-supplies-landing,
    &.layout-supplies-landing,
    &.layout-create {
      &::before {
        display: none;
      }
    }
  }
}

.ct-form-label {
  font-weight: 600;
}

// temporary

.form-control {
  border-color: $silver-lighten;

  &.alert-border {
    border-color: $warning !important;
  }
}

.ct-padding_default {
  padding: 7px 15px;
}

.ct-padding_extra {
  padding: 15px;
}

.ct-padding_zero {
  padding: 0;
}

.ct-card-divider {
  border-bottom: 1px solid $border-primary;
  display: block;
  height: 1px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 600px) {
    border-top-left-radius: 0;
  }
}

.ct-card_graduated-demo_done-link {
  color: $white;
  margin-right: 15px;

  &:hover {
    color: $white;
    text-decoration: underline;
  }

  @media screen and (max-width: 400px) {
    display: block;
    float: none !important;
  }
}

#mobile-adhesion-ad {
  z-index: 500 !important;
}

#desktop-anchor {
  z-index: 5002 !important;

  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

// Hack for GumGum ads
[data-gg-moat] {
  z-index: 5000 !important;
}

#classtag_highimpact > div {
  height: 0;
  overflow: hidden;
}

.context-header-management {
  height: 35px;
  margin-right: 5px;
  position: relative;
  top: -15px;
  width: 35px;

  @media screen and (max-width: 767px) {
    top: 0;
  }
}

.context-header-management,
.school-header,
.district-header {
  .ctui-actions-list-item {
    padding: 10px 15px;
  }
}


.context-header-settings {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 21px;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  margin: 15px 0 0 15px;
  max-width: 42px;
  min-width: 42px;
  position: absolute;
  right: 15px;
  top: 0;
  transition: all 0.3s linear;

  &:hover {
    background-color: $image-bg;
    transition: color 0.2s;

    svg {
      opacity: 0.6;

      path {
        fill: $white;
        transition: all 0.4s linear;
      }
    }
  }
}

.ctui-file-preview {
  img {
    height: 24px;
  }

  .ctui-file-preview-play {
    img {
      height: 44px;
    }
  }
}

.ios-app-gap-handler {
  min-height: 100vh;
}

.ct-block_narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}

.sidebar-is-opened {
  @media screen and (min-width: 1023px) {
    height: 100%;
    overflow: hidden;
  }

  .ct-yield,
  .conversations {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.ct-yield {
  max-width: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 50px;
  padding-top: 110px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-top: 85px;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 120px;
  }

  .public-announcement & {
    padding-bottom: 40px;
  }

  .ios-app-gap-handler & {
    min-height: 92vh;
  }

  .layout-public-supplies & {
    padding-bottom: 0;

    @media screen and (max-width: 991px) {
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .ct-yield {
    & > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.is-call-from-desktop {
  .ct-yield {
    padding-top: 175px;

  }

  .ct-create-body {
    padding-top: 135px;
  }

  .ct-navbar,
  .ctui-modal-backdrop,
  .ct-create-header {
    top: 65px;
  }

  .ctui-modal {
    max-height: calc(94vh - 65px);
    top: calc(65px + 3vh);
  }

  &.ct-body-li:not(.conversations-layout)::before {
    top: 65px;
  }
}

.ct-yield-container {
  min-height: 100vh;
  width: 100%;

  .ct-yield {
    padding-left: 64px;
    transition: padding-right .2s ease-in;

    .nav-bar--is-open & {
      padding-left: 224px;
    }
  }

}

.ct-message_warning_grey {
  background-color: #f2f2f7;
  background-image: url('../../assets/warning.png');
  background-position: 10px center;
  background-repeat: no-repeat;
  background-size: 16px 15px;
  border: 1px solid $ss-danger-600;
  border-radius: 6px;
  line-height: 1em;
  margin-bottom: 10px;
  padding: 10px 5px 10px 32px;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('../../assets/warning@2x.png');
    min-height: 16px;
  }
}

.create-form-two-columns {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }

  .create-form-column {
    position: relative;
    width: 50%;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
      padding: 0 !important;
      width: 100%;

      .ptc-timepicker {
        .exclamation {
          right: 15px;
        }
      }

      &:first-child {
        margin-top: 0;

        &:nth-last-child(2) ~ .create-form-column {
          margin-bottom: 15px;
        }
      }
    }

    &:nth-child(odd) {
      padding-right: 6px;
    }

    &:nth-child(even) {
      padding-left: 6px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 15px;
    }
  }
}

.ct-create {
  .ct-icon-info-tooltip {
    position: absolute;
    right: -15px;
    top: 0;
  }
}

.ct-text_light-grey {
  color: $ss-gray-300;
}

.ct-text_middle-grey {
  color: $ss-gray-600;
}

.ct-text_xl {
  font-size: 18px;
}

.ct-text_md {
  font-size: 16px;
}

.ct-text_sm {
  font-size: 12px;
}

.ct-text_light {
  font-weight: 400;
}

.ct-text_bold {
  font-weight: 600;
}

.ct-text_danger {
  color: $ss-danger-600;
}

.ct-text_warning {
  color: $ss-danger-600;
}

.ct-text_xxl {
  font-size: 22px;
}

.ct-text_xs {
  font-size: 10px;
}

.ct-text_semibold {
  font-weight: 400;
}

.ct-text_italic {
  font-style: italic;
}

.ct-bg_light-grey {
  background-color: #f5f6f8;
}

.ct-bg_warning {
  background-color: $ss-danger-50;
}

.ct-bg-none {
  background: none;
}

.ct-border-bottom {
  border-bottom: 1px solid $border-primary;
}

.ct-ellipsis {
  @include ellipsis(100%);
}

.ct-display_inline-block_vertical {
  display: inline-block;
  vertical-align: middle;
}

.ct-display_block {
  display: block;
}

.ct-margin-left-10 {
  margin-left: 10px;
}

.ct-margin-right-10 {
  margin-right: 10px;
}

.visibility-sensor {
  height: 1px;
}

.display-none {
  display: none;
}

.display-none-important {
  display: none !important;
}

.display-inline-block {
  display: inline-block;
}

.ct-box_rounded {
  border-radius: 6px;
}

@media screen and (max-width: 0), screen and (min-width: 768px) {
  .show-for-small-only {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .show-for-small-devices {
    display: none !important;
  }
}

.text-right {
  text-align: right;
}

.clearfix {
  clear: both;
}

.img-circle {
  border-radius: 50%;
}

.ct-display-mobile,
.display-mobile {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
}

.ct-display-tablet {
  display: none;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
}

.ct-display-desktop {
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
  }
}

.display-desktop-tablet {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.ct-table {
  table-layout: fixed;
  width: 100%;

  td {
    padding: 7px 10px;
  }
}

.ct-table__td_index {
  width: 50px;
}

// Lightbox modal styles
.ReactModalPortal {
  position: relative;
  z-index: 11001;
}

.ReactModal__Overlay {
  z-index: 12000 !important;
}

.app-updating {
  background: $white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: calc(50% - 40px);
  position: fixed;
  top: 0;
  z-index: 10;
}

.ck-content {
  ul, ol {
    margin-bottom: 10px;
    padding-inline-start: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
}

@import '@classtag/ui/lib/colors';
@import '../../../styles/variables';

.sing-in-form-input {
  margin-bottom: 15px;
}

.sign-in-sso-wrap {
  margin-top: 20px;

  .sign-in-buttons-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.sign-in-sso-buttons-container {
  align-items: center;
  display: grid;
  flex-wrap: wrap;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr;
}

.sign-in-sso-title {
  align-items: center;
  color: $text-secondary;
  display: flex;
  margin: 20px 0;
  text-align: center;
  white-space: nowrap;
  width: 100%;

  &::before {
    background-color: $light-grayish-cyan;
    content: '';
    display: block;
    height: 1px;
    margin-right: 10px;
    width: 100%;
  }

  &::after {
    background-color: $light-grayish-cyan;
    content: '';
    display: block;
    height: 1px;
    margin-left: 10px;
    width: 100%;
  }

  .sso-btn {
    &.ctui-button {
      align-items: center;
      border-radius: 2px;
      height: 2.5rem;
      justify-content: center;
      max-width: 100%;
      padding-left: 40px;
      padding-right: 8px;
      position: relative;
      width: 100%;
    }
  }

  .sso-image-wrap {
    align-items: center;
    background: $white;
    border-radius: 1px;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 0;
    min-width: 40px;
    position: absolute;
    top: 0;
    transform: scale(0.95);
    width: 40px;
  }

  .sso-google-btn {
    background: $google-bg;
    box-shadow: $google-shadow;
    color: $white;

    &:hover {
      box-shadow: $google-shadow-hover;
    }

    &:focus,
    &:active {
      background: $google-bg-active;
      box-shadow: $google-shadow-hover;
    }
  }

  .sso-fb-btn {
    &.ctui-button {
      background: $facebook-bg;
      color: $white;
      font-size: 1rem;
      font-weight: 600;

      @media (max-width: 767px) {
        font-size: .875rem;
      }
    }
  }

  .sso-apple-btn {
    &.ctui-button {
      background: $black;
      color: $white;
      font-size: 1.1875rem;
      font-weight: 400;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }

      &:active,
      &:focus {
        color: $apple-pressed;

        svg {
          fill: $apple-pressed;
        }
      }

      .sso-image-wrap {
        background: transparent;
      }
    }
  }

  .sso-ms-btn {
    &.ctui-button {
      background: $ms-bg;
      color: $white;
      font-size: .9375rem;
      font-weight: 400;

      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }

      &:active,
      &:focus {
        background: $black;
      }

      .sso-image-wrap {
        background: transparent;
      }
    }
  }

  .sso-classlink-btn {
    &.ctui-button {
      background: $classlink-bg;
      color: $white;
      font-weight: 600;

      &:hover {
        background: $classlink-bg-hover;
      }

      .sso-image-wrap {
        background: transparent;
      }
    }
  }

  .sso-clever-btn {
    &.ctui-button {
      background: $clever-bg;
      color: $white;
      font-size: .9375rem;

      &:hover {
        background: $clever-bg-hover;
      }

      .sso-image-wrap {
        background: transparent;
      }
    }
  }
}

.signin-form-forgot-password {
  align-items: self-start;
  display: flex;
  justify-content: space-between;
}

.signin-form-forgot-password-checkbox {
  align-items: center;
  display: flex;

  @media (max-width: 374px) {
    margin-bottom: 12px;
  }

  .ct-checkbox__label {
    height: 22px;
  }

  span {
    > div {
      margin: 0;
    }
  }
}

.signin-form-forgot-password-link {
  height: 22px;
}

.signin-form-button {
  font-size: .875rem;
  height: 38px;
  margin-top: 30px;
  width: 100%;
}

.signin-form-create-account {
  border: 2px solid $primary;
  color: $primary;
  width: 100%;

  .form-signup & {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

.sign-up-form-input {
  margin-bottom: 15px;

  input {
    height: 39px;
  }
}

.sign-up-form-button {
  height: 38px;
}

.sign-up-form-btn {
  font-size: .875rem;
  height: 38px;
}

.user-permissions-form {
  & > p {
    font-size: .875rem;
    line-height: 19px;
    margin-bottom: 15px;
  }
}

.user-permissions-form-checkboxes {
  @media (max-width: 767px) {
    padding: 0 0 8px;
  }

  .user-permissions-form-checkboxes-description {
    font-size: .875rem;
    line-height: 1.1875rem;
    margin: 7px 0 20px 28px;
  }
}

.user-permissions-form-checkbox {
  margin: 0;

  & p {
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.1875rem;
    margin-left: 10px;
  }
}

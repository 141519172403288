@import "@classtag/ui/lib/colors";

.exporting-page {
  min-height: 100vh;
  width: 100%;

  .loader {
    cursor: not-allowed;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10000;

    & > div {
      &:first-child {
        top: calc(50% - 30px);
      }
    }
  }
}

.loader-text {
  color: $primary;
  left: 50%;
  margin-left: -43px;
  margin-top: 70px;
  position: absolute;
  top: 50%;
}

@import '@classtag/ui/lib/colors';

.user-account-layout {
  h1 {
    align-items: center;
    color: $navy-darken;
    display: flex;
    font-size: 1.125rem;
    font-weight: 600;
    height: 65px;
    line-height: 22px;
    margin: 0;
    padding: 0 10px;

    .user-account-row-back-link {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 0 0 0 -10px;
      padding: 20px;
    }
  }

  h2 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 25px;
    margin: 0;
    padding: 10px 15px;
  }

  h2.user-account-top-title {
    padding-top: 0;
  }

  .user-account-row {
    border-bottom: 1px solid $light-grayish-cyan;
    position: relative;
  }

  .user-account-row-input {
    color: $navy-darken;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 15px;

    .form-switcher {
      .form-switcher-checkbox-label {
        font-weight: 400;
      }
    }
  }
}

.user-account-layout--student {
  h1 {
    padding: 22px 15px;
  }
}

.user-classrooms-list,
.user-archived-classrooms-list,
.user-unschooled-classrooms-list,
.user-districts-list,
.user-family-children-list,
.user-family-co-parents-list,
.user-schools-list {
  border-bottom-color: $light-grayish-cyan;
  padding: 0;

  &--header {
    border-bottom-color: $light-grayish-cyan;
    padding: 8px 9px !important;
  }

  &--title {
    color: $navy-darken;
    font-weight: 600;
    line-height: 25px;
    width: 100%;
  }

  &--body {
    padding: 0 15px;

    & > div {
      &:nth-last-child(2):is(.user-context-place-cell--collapsed) {
        border-bottom: 0;
      }
    }

    & > .display-desktop-tablet,
    & > .display-mobile {
      &:last-child,
      &:nth-last-child(2) {
        border-bottom: 0;
      }
    }

    @media screen and (max-width: 480px) {
      padding: 0 0 0 15px;
    }
  }

  .user-classrooms-list {
    padding: 0;
  }

  .user-classrooms-list--collapsed {
    display: none;
  }
}

.user-classrooms-list--body {
  padding: 0 0 0 15px;

  @media screen and (min-width: 768px) {
    padding: 0 0 0 48px;
  }
}

@import "@classtag/ui/lib/colors";

.offline-status {
  backface-visibility: hidden;
  background-color: $ss-gray-850;
  box-sizing: border-box;
  height: 65px;
  left: 0;
  margin: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 5005;

  .offline-status-button-close {
    height: auto;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 25px;

    path {
      fill: $white;
    }
  }
}

.offline-status-title {
  align-items: center;
  color: $white;
  display: flex;
  font-size: 20px;
  justify-content: center;
  padding-top: 21px;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}

.offline-status-dot {
  animation: 1s blink infinite;

  &:nth-child(2) {
    animation-delay: 250ms;
  }

  &:nth-child(3) {
    animation-delay: 500ms;
  }
}
